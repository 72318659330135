import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';
const intialState = {
    loading: false,
    data: {},
    companyListLoading: false,
    companyList: [],
};
const getActiveCompanyListStart = (state) => {
    return updateObject(state, { companyListLoading: true, error: false });
};
const getActiveCompanyListStop = (state) => {
    return updateObject(state, { companyListLoading: false, error: false });
};
const getActiveCompanyListSuccess = (state, action) => {
    let companyList = [...action.data];
    if(process.env.REACT_APP_DISABLE_SA_DETAILS === '1' && localStorage.getItem('company').toLowerCase() !== process.env.REACT_APP_SA_COMPANY) {
        companyList = companyList.filter(c=>c.name.toLowerCase()!==process.env.REACT_APP_SA_COMPANY);
    }
    companyList.sort((a,b) => {
        if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
        }
        if (b.name.toUpperCase() < a.name.toUpperCase()) {
            return 1;
        }
        return 0;
    });
    return updateObject(state, { companyListLoading: false, companyList, error: false });
};
const getActiveCompanyListFailed = (state, action) => {
    return updateObject(state, { companyCategoryLoading: false, error: action.error });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ACTIVE_COMPANY_LIST_START:
            return getActiveCompanyListStart(state, action);
        case actionTypes.GET_ACTIVE_COMPANY_LIST_STOP:
            return getActiveCompanyListStop(state, action);
        case actionTypes.GET_ACTIVE_COMPANY_LIST_SUCCESS:
            return getActiveCompanyListSuccess(state, action);
        case actionTypes.GET_ACTIVE_COMPANY_LIST_FAILED:
            return getActiveCompanyListFailed(state, action);
        case actionTypes.GET_SHIPMENT_DETAILS_START:
        default:
            return state;
    }
};
export default reducer;
