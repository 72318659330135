import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const intialState = {
    groups:[],
    paginationGroups:[],
    groupsLoading: false,
    groupsMeta: {},
    groupUserList:[],
    groupCompanyList:[],
    groupFlag:false,
    groupDeleteObj:{},
    groupCompanyLoading:false,
    groupUserLoading:false,
    groupLoading: true,
    viewGroup:[],
    viewGroupLoading:false,
    makeGroupAdminMessage:'',
    limitedGroupListLoading: false,
    limitedGroupList:[],
    showNewGroupOnTopFlag: false,
};

const sendGroupsStart = (state, action) => {
    return updateObject(state, { 
        groups: [],
        groupsLoading: true 
    });
};
const sendGroups = (state, action) => {
    const groups = [...action.data.data];
    const groupsMeta = {...action.data.meta};

    // if(process.env.REACT_APP_DISABLE_SA_DETAILS === '1' && localStorage.getItem('company').toLowerCase() !== process.env.REACT_APP_SA_COMPANY) {
    //     groups = groups.map(g=>{ return {...g, company_group: g?.company_group?.filter(cg=>cg.company?.name?.toLowerCase()!==process.env.REACT_APP_SA_COMPANY)}});
    //     groups.map(g => {
    //         g.companiesCount = g.company_group?.length||0;
    //     });
    // }
    return updateObject(state, { groups, paginationGroups: groups, groupsMeta, groupsLoading: false });
};

const groupUserListStart = (state, action) => {
    return updateObject(state, { groupUserLoading: true , groupUserList: []});
};
const sendGroupUserList = (state, action) => {
    let LIST = [...action.data];
    if(process.env.REACT_APP_DISABLE_SA_DETAILS === '1' && localStorage.getItem('company').toLowerCase() !== process.env.REACT_APP_SA_COMPANY) {
        LIST = LIST.filter(user=>user.company?.toLowerCase()!==process.env.REACT_APP_SA_COMPANY)
    }
    if (action.groupUsersListMeta) {
        if(action.groupUsersListMeta.current_page === 1) {
            state.groupUserList = [...LIST];
        } else {
            LIST = [...state.groupUserList, ...action.data];
            state.groupUserList = [...LIST];
        }
    }
    return updateObject(state, { groupUserList: LIST, groupUserLoading: false, groupUsersListMeta: action.groupUsersListMeta});
};

const sendNewGroupStart = (state, action) => {
    return updateObject(state, { errors: {}, groupFlag: true, error_message: '' });
};

const sendNewGroupSuccess = (state, action) => {
    return updateObject(state, { groupFlag: false, createGroupResponse: action.data });
};

const sendNewGroupFailed = (state, action) => {
    return updateObject(state, { groupFlag: false, groupFormErrors: action.data });
};

const clearGroupFormErrors = (state, action) => {
    return updateObject(state, { groupFormErrors: {}, groupFlag: false});
};

const showNewGroupOnTop = (state, action) => {
    return updateObject(state, { showNewGroupOnTopFlag : action.flag});
};

const groupCompanyListStart = (state, action) => {
    return updateObject(state, { groupCompanyList: [], groupCompanyLoading: true });
};

const sendGroupCompanyList = (state, action) => {
    return updateObject(state, { groupCompanyList: action.list, groupCompanyLoading: action.flag });
};

const viewGroupStart = (state,action)=>{
    return updateObject(state,{viewGroup:[],viewGroupLoading:true});
}
const viewGroupSuccess = (state,action)=>{
    return updateObject(state,{viewGroup:action.data,viewGroupLoading:false})
}

const makeGroupAdminSucess = (state,action) =>{
    return updateObject(state,{makeGroupAdminMessage:action.message});
}

const sendDeleteGroupSuccess = (state, action) => {
    return updateObject(state, { groupDeleteObj: action.data });
};

const getLimitGroupListStart = (state, action) => {
    return updateObject(state, { limitedGroupListLoading: true });
};

const getLimitGroupListSuccess = (state, action) => {
    const limitedGroupList = [...action.data.data];
    return updateObject(state, { limitedGroupList, limitedGroupListLoading: false });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_GROUPS_SUCCESS:
            return sendGroups(state, action);
        case actionTypes.GET_GROUPS_START:
            return sendGroupsStart(state, action);
        case actionTypes.GET_GROUPS_USERS_LIST_START:
            return groupUserListStart(state, action);
        case actionTypes.GET_GROUPS_USERS_SUCCESS:
            return sendGroupUserList(state, action);
        case actionTypes.ADD_NEW_GROUP_START:
            return sendNewGroupStart(state, action);
        case actionTypes.ADD_NEW_GROUP_SUCCESS:
            return sendNewGroupSuccess(state, action);
        case actionTypes.ADD_NEW_GROUP_FAILED:
            return sendNewGroupFailed(state, action);
        case actionTypes.CLEAR_GROUP_FORM_ERRORS:
            return clearGroupFormErrors(state, action);
        case actionTypes.SHOW_NEW_GROUP:
            return showNewGroupOnTop(state, action);
        case actionTypes.GET_GROUPS_COMPANIES_LIST_START:
            return groupCompanyListStart(state, action);
        case actionTypes.GET_GROUPS_COMPANIES_LIST_SUCCESS:
            return sendGroupCompanyList(state, action);
        case actionTypes.DELETE_GROUP_SUCCESS:
            return sendDeleteGroupSuccess(state, action);
        case actionTypes.VIEW_GROUP_START:
            return viewGroupStart(state,action);
        case actionTypes.VIEW_GROUP_SUCCESS:
            return viewGroupSuccess(state,action);
        case actionTypes.MAKE_GROUP_ADMIN_SUCCESS:
            return makeGroupAdminSucess(state,action);
        case actionTypes.GET_GROUP_LIST_LIMITED_START:
            return getLimitGroupListStart(state, action);
        case actionTypes.GET_GROUP_LIST_LIMITED:
            return getLimitGroupListSuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
