import React from 'react';
const ContactSupportIcon = (props) => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3582_11782)">
            <path className='profile-pop-icon' d="M12.3332 16.0007H8.68924C3.80724 16.096 -0.497429 12.1707 0.0372374 7.25735C0.203076 5.51453 0.934909 3.87392 2.12092 2.58618C3.30694 1.29844 4.88192 0.434383 6.60524 0.126013C7.73788 -0.0838355 8.90303 -0.0390228 10.0162 0.257202C11.1294 0.553427 12.1627 1.09364 13.0412 1.83868C13.9711 2.62051 14.718 3.59691 15.2292 4.69896C15.7404 5.801 16.0035 7.00183 15.9999 8.21668V12.334C15.9988 13.3061 15.6122 14.2382 14.9248 14.9256C14.2374 15.613 13.3054 15.9996 12.3332 16.0007ZM8.0199 2.00068C6.52291 1.99939 5.07924 2.55645 3.97112 3.56297C2.86301 4.5695 2.17012 5.95312 2.0279 7.44335C1.94638 8.23822 2.03364 9.04134 2.28397 9.80015C2.53431 10.559 2.94207 11.2564 3.48057 11.8467C4.15207 12.5471 4.96185 13.1003 5.85852 13.4711C6.75519 13.8419 7.71916 14.0223 8.68924 14.0007H12.3332C12.7753 14.0007 13.1992 13.8251 13.5117 13.5125C13.8243 13.2 13.9999 12.776 13.9999 12.334V8.21668C14.0032 7.29379 13.8038 6.38143 13.416 5.54398C13.0281 4.70654 12.4612 3.96443 11.7552 3.37001C10.7126 2.4839 9.38824 1.99841 8.0199 2.00068ZM9.66657 5.33401C9.20282 5.35716 8.76711 5.56304 8.45477 5.90662C8.14243 6.25019 7.97888 6.70349 7.9999 7.16735C8.0195 6.93659 7.99097 6.70428 7.91611 6.48512C7.84125 6.26596 7.7217 6.06474 7.56502 5.8942C7.40834 5.72365 7.21796 5.5875 7.00593 5.49436C6.79389 5.40122 6.56483 5.35313 6.33324 5.35313C6.10165 5.35313 5.87258 5.40122 5.66055 5.49436C5.44851 5.5875 5.25813 5.72365 5.10145 5.8942C4.94478 6.06474 4.82523 6.26596 4.75037 6.48512C4.67551 6.70428 4.64697 6.93659 4.66657 7.16735C4.66657 8.60935 6.54657 10.4013 7.48324 11.1527C7.62981 11.2702 7.81205 11.3342 7.9999 11.3342C8.18776 11.3342 8.37 11.2702 8.51657 11.1527C9.45324 10.4007 11.3332 8.60935 11.3332 7.16735C11.3543 6.70349 11.1907 6.25019 10.8784 5.90662C10.566 5.56304 10.1303 5.35716 9.66657 5.33401Z" fill="#333333" />
        </g>
        <defs>
            <clipPath id="clip0_3582_11782">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default ContactSupportIcon;
