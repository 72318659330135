import React from 'react';
const RiskIcon = (props) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5900_3032)">
            <path className='nav-icon' d="M8.53417 14.465C7.59017 13.521 6.33417 13.001 4.99917 13.001C3.66417 13.001 2.40817 13.521 1.46317 14.465C-0.486828 16.414 -0.486827 19.587 1.48917 21.561L3.47917 23.41C3.90717 23.808 4.45517 24.006 5.00317 24.006C5.55117 24.006 6.10317 23.806 6.53117 23.406L8.53217 21.535C9.47717 20.591 9.99717 19.335 9.99717 18C9.99717 16.665 9.47717 15.409 8.53217 14.464L8.53417 14.465ZM17.4792 10.41C17.9072 10.807 18.4552 11.006 19.0032 11.006C19.5512 11.006 20.1042 10.806 20.5322 10.406L22.5332 8.535C24.4832 6.586 24.4832 3.413 22.5332 1.464C21.5882 0.52 20.3332 0 18.9982 0C17.6632 0 16.4072 0.52 15.4622 1.464C13.5122 3.413 13.5122 6.586 15.4882 8.56L17.4782 10.409L17.4792 10.41ZM23.9992 20C23.9992 22.206 22.2052 24 19.9992 24H10.9992C10.4472 24 9.99917 23.552 9.99917 23C9.99917 22.448 10.4472 22 10.9992 22H19.9992C21.1022 22 21.9992 21.103 21.9992 20C21.9992 18.897 21.1022 18 19.9992 18H14.9992C12.7932 18 10.9992 16.206 10.9992 14C10.9992 12.308 12.0712 10.792 13.6662 10.228C14.1882 10.043 14.7582 10.317 14.9422 10.838C15.1262 11.359 14.8532 11.93 14.3322 12.114C13.5352 12.396 12.9992 13.154 12.9992 14C12.9992 15.103 13.8962 16 14.9992 16H19.9992C22.2052 16 23.9992 17.794 23.9992 20Z" fill="#999999" />
        </g>
        <defs>
            <clipPath id="clip0_5900_3032">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default RiskIcon;
