import React from 'react';
const DocIcon = (props) => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3565_35448)">
            <path className='profile-pop-icon' d="M11 8.33335C11 8.59857 10.8946 8.85292 10.7071 9.04045C10.5196 9.22799 10.2652 9.33335 10 9.33335H5.33333C5.06812 9.33335 4.81376 9.22799 4.62623 9.04045C4.43869 8.85292 4.33333 8.59857 4.33333 8.33335C4.33333 8.06813 4.43869 7.81378 4.62623 7.62624C4.81376 7.4387 5.06812 7.33335 5.33333 7.33335H10C10.2652 7.33335 10.5196 7.4387 10.7071 7.62624C10.8946 7.81378 11 8.06813 11 8.33335ZM8 10.6667H5.33333C5.06812 10.6667 4.81376 10.772 4.62623 10.9596C4.43869 11.1471 4.33333 11.4015 4.33333 11.6667C4.33333 11.9319 4.43869 12.1863 4.62623 12.3738C4.81376 12.5613 5.06812 12.6667 5.33333 12.6667H8C8.26522 12.6667 8.51957 12.5613 8.70711 12.3738C8.89464 12.1863 9 11.9319 9 11.6667C9 11.4015 8.89464 11.1471 8.70711 10.9596C8.51957 10.772 8.26522 10.6667 8 10.6667ZM14.3333 5.43801V12.3333C14.3323 13.3055 13.9456 14.2375 13.2582 14.9249C12.5708 15.6123 11.6388 15.999 10.6667 16H4.66667C3.69453 15.999 2.76252 15.6123 2.07511 14.9249C1.38771 14.2375 1.00106 13.3055 1 12.3333V3.66668C1.00106 2.69455 1.38771 1.76253 2.07511 1.07513C2.76252 0.387722 3.69453 0.00107359 4.66667 1.48073e-05H8.89533C9.37705 -0.00135889 9.85425 0.0928613 10.2993 0.277218C10.7443 0.461574 11.1484 0.732402 11.488 1.07401L13.2593 2.84535C13.6009 3.18503 13.8717 3.58907 14.056 4.0341C14.2404 4.47914 14.3346 4.95631 14.3333 5.43801ZM12.3333 12.3333V6.00001H9.66667C9.31304 6.00001 8.97391 5.85954 8.72386 5.60949C8.47381 5.35944 8.33333 5.0203 8.33333 4.66668V2.00001H4.66667C4.22464 2.00001 3.80072 2.17561 3.48816 2.48817C3.17559 2.80073 3 3.22465 3 3.66668V12.3333C3 12.7754 3.17559 13.1993 3.48816 13.5119C3.80072 13.8244 4.22464 14 4.66667 14H10.6667C11.1087 14 11.5326 13.8244 11.8452 13.5119C12.1577 13.1993 12.3333 12.7754 12.3333 12.3333Z" fill="#333333" />
        </g>
        <defs>
            <clipPath id="clip0_3565_35448">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default DocIcon;
