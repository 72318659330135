import React from 'react';
const LogoutIcon = (props) => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3450_12517)">
            <path className='profile-pop-icon' d="M5.33527 14H2.27461C2.12417 13.9996 2.0023 13.8778 2.00195 13.7273V2.27266C2.00233 2.12222 2.12417 2.00034 2.27461 2H5.33527C5.88755 2 6.33527 1.55228 6.33527 1C6.33527 0.447719 5.88755 0 5.33527 0H2.27461C1.02005 0.00146875 0.00342187 1.01812 0.00195312 2.27266V13.7273C0.00342187 14.9819 1.02005 15.9985 2.27461 16H5.33527C5.88755 16 6.33527 15.5523 6.33527 15C6.33527 14.4477 5.88755 14 5.33527 14Z" fill="#333333" />
            <path className='profile-pop-icon' d="M15.1239 5.87875L12.5359 3.29275C12.1453 2.90228 11.5121 2.90244 11.1216 3.29309C10.7311 3.68375 10.7313 4.31697 11.1219 4.70744L13.3966 6.98144L4.33594 7.00009C3.78366 7.00009 3.33594 7.44781 3.33594 8.00009C3.33594 8.55238 3.78366 9.00009 4.33594 9.00009L13.4333 8.98344L11.1219 11.2948C10.7456 11.699 10.7683 12.3318 11.1725 12.7081C11.5567 13.0658 12.1521 13.0655 12.5359 12.7074L15.1219 10.1214C16.2935 8.94987 16.2935 7.05038 15.122 5.87881L15.1219 5.87878L15.1239 5.87875Z" fill="#333333" />
        </g>
        <defs>
            <clipPath id="clip0_3450_12517">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default LogoutIcon;
