import 'core-js';
//import React, { Component } from 'react';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client'
import { createBrowserHistory } from 'history';
import { BrowserRouter, Routes, Route  } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import rootReducer from './redux-store/reducers';
import { googleAnalyticActions } from './init-ga';
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import "react-datepicker/dist/react-datepicker.css"
const Header = require(`./components/Header/${process.env.REACT_APP_CUSTOMER_ID}/Header`).default;

require(`./config/${process.env.REACT_APP_CUSTOMER_ID}/theme.css`);
require(`./index.css`);

const GroupManagement = React.lazy(() => import('./components/Administration/GroupManagement'));
const CompanyManagement = React.lazy(() => import('./components/Administration/CompanyManagement'));
const Users = React.lazy(() => import('./components/Administration/UserManagement'));
// const GroupCompanies = React.lazy(() => import('./GroupCompanies'));
const ForgotPasswordPage = React.lazy(() => import('./components/Auth/ForgotPasswordPage'));
const Auth0Login = React.lazy(() => import('./components/Auth/Auth0Login'));
const Logout = React.lazy(() => import('./components/Auth/Logout'));
const ForgotPassword = React.lazy(() => import('./components/Auth/ForgotPassword'));
const SetPassword = React.lazy(() => import('./components/Auth/SetPassword'));
const TermsOfService = React.lazy(() => import('./components/Auth/TermsOfService'));
const VerificationCode = React.lazy(() => import('./components/Auth/VerificationCode'));
const RiskAssessment = React.lazy(() => import('./components/RiskIQ/RiskAssessment'));

// External Event url 

const RiskLaneDetailsExternal= React.lazy(() => import('./components/RiskIQ/ExternalEventRiskDetailView'));

const RiskAssessmentExternal = React.lazy(() => import('./components/RiskIQ/RiskAssessmentExternal'));
// External Event url end
const RiskLaneDetails= React.lazy(() => import('./components/RiskIQ/RiskDetailsView'));
const RiskPlanning = React.lazy(() => import('./components/RiskIQ/RiskPlanning'));
//const RiskplanningDetails = React.lazy(() => import('./components/RiskIQ/RiskPlanningDetails'));
const ActiveDashboard = React.lazy(() => import('./components/Pdf/ActiveDashboard'));
const PassiveDashboard = React.lazy(() => import('./components/Pdf/TemperatureDashboard'));
//const CompanyManagement = React.lazy(() => import('./CompanyManagement'));
// const Management = React.lazy(() => import('./Management'));
const UserProfile = React.lazy(() => import('./components/UserProfile/UserProfile'));
const TermsPage = React.lazy(() => import('./components/Pages/TermsPage'));
const PrivacyPage = React.lazy(() => import('./components/Pages/PrivacyPage'));
const Pages = React.lazy(() => import('./components/Pages/Pages'));

const initialState = window.__INITIAL_STATE__;
delete window.__INITIAL_STATE__;


const container = document.getElementById('root');
const root = createRoot(container);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(thunk)
));
const hist = createBrowserHistory();
const ROLE = localStorage.getItem('roles');
const sessionExpired = sessionStorage.getItem('sessionExpired');

const wordsToCheck = ["login", "forgotPassword", "updatePassword", "verificationCode",''];

const onRedirectCallback = (appState) => {
    hist.push(
        appState && appState.returnTo ? appState.returnTo : window.location.pathname
    );
};
const providerConfig = (()=>{
    return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    onRedirectCallback,
    cacheLocation: 'localstorage',
    authorizationParams: {
        redirect_uri: process.env.REACT_APP_AUTH0_CALLBACK_URL,
        // redirect_uri: 'http://localhost:3000',
        SessionExpired: sessionExpired,
        ...(process.env.REACT_APP_AUDIENCE ? { audience: process.env.REACT_APP_AUDIENCE } : null),
    },
    }
    }
)();

Sentry.init({
    environment: "production",
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["https://api-dev.lynxlogix.carrier.com", "https://echoserver-dev.lynxlogix.carrier.com"],
    // Session Replay
    replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const appConfig = {
    pdf: Number(localStorage.getItem('pdfParser')),
    riskiq: Number(localStorage.getItem('riskIQ')),
};

root.render(
    <Auth0Provider {...providerConfig}>
    <Provider store={store}>
        <Suspense fallback={wordsToCheck.some((word) => window.location.href.includes(word)) ? <div className='loading'/> : <><Header /><div className='loading' style={{top: '70px'}}/></> }>
        <BrowserRouter  history={hist}>
            <Routes> 
                <Route path="/pages/:page" element={<Pages />} />
                <Route path="/terms" element={<TermsPage />} />
                <Route path="/privacy" element={<PrivacyPage/>} />
                {/*{ROLE === 'super admin' ? <Route path="/companies" element={Company} /> : ROLE === 'group admin' ? <Route path="/companies" element={GroupCompanies} /> : ''}*/}
                {/* {ROLE === 'company admin' ? <Route path="/users" element={Users} /> : ''} */}
                <Route path="/users" element={<Users/>} />
                {ROLE === 'super admin' ? <Route path="/groups/:groupId/:companyId" element={<Users />} /> : ''}
                {ROLE === 'super admin' ? <Route path="/groups/:groupId" element={<CompanyManagement />} /> : ''}
                {ROLE === 'super admin' ? <Route path="/groups" element={<GroupManagement />} /> : ''}
                {ROLE === 'super admin' || ROLE === 'group admin' ? <Route path="/companies/:companyId" element={<Users />} /> : ''}
                {ROLE === 'super admin' || ROLE === 'group admin' ? <Route path="/companies" element={<CompanyManagement />} />: ''}
                {/* <Route path="/management/:groupView" element={Management} /> */}
                {/* <Route path="/management" element={Management} /> */}
                <Route path="/login" element={<Auth0Login />} />
                <Route path="/forgotPassword/:encrypt" element={<SetPassword />} />
                <Route path="/signOut" element={<Logout />} />
                <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
                <Route path="/termsofservice" element={<TermsOfService />} />
                <Route path="/profile" element={<UserProfile />} />
                <Route path="/verificationCode/:encrypt" element={<VerificationCode />} />
                <Route path="/temperature_dashboard" element={<PassiveDashboard />} />
                {appConfig.riskiq ? <Route path="/active_dashboard" element={<ActiveDashboard />} /> : ( '' )}
                {appConfig.riskiq ? <Route path="/riskiq/:laneId" element={<RiskLaneDetailsExternal  />} />  : ( '' )}
                {/* {appConfig.riskiq ? <Route path="/riskiqexternal/:laneId" element={<RiskLaneDetails />} />  : ( '' )} */}
                
                {appConfig.riskiq ? <Route path="/riskiq" element={<RiskAssessmentExternal />} />  : ( '' )}
                {/* {appConfig.riskiq ? <Route path="/riskiqexternal" element={<RiskAssessment />} />  : ( '' )} */}
                
                {appConfig.riskiq ? <Route path="/riskplanning/:type?" element={<RiskPlanning />} /> : ( '' )}
                {/* {appConfig.riskiq ? <Route path="/riskplanningDetails" element={RiskplanningDetails} /> : ( '' )} */}
                <Route path="/" element={<Auth0Login />} />

                <Route element={<Auth0Login />} />
            </Routes> 
        </BrowserRouter >
        </Suspense>
    </Provider>
    </Auth0Provider>
);
if(process.env.REACT_APP_ENV === 'PRODUCTION') {
    window.console.log = () => {};
}
window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
        if(localStorage.getItem('access_token')) {
            if(window.location.pathname === '/login'){
                window.location.href = '/dashboard';
            }
        } else {
            console.log('NO access_token, logging out......');
            window.location.href = '/login';
        }
    } else {
        console.log('This page was loaded normally.');
    }
});
googleAnalyticActions.initGoogleAnalytics(process.env.REACT_APP_GA_KEY);