import { combineReducers } from 'redux';
import loginReducer from './reducers/login';
import dashboardReducer from './reducers/dashboard';
import groupReducer from './reducers/groups';
import companyReducer from './reducers/company';
import userReducer from './reducers/users';
import pdfParserReducer from './reducers/pdf';
import riskIQReducer from './reducers/riskIQ';

export default combineReducers({
    login: loginReducer,
    dashboard: dashboardReducer,
    group: groupReducer,
    company: companyReducer,
    user: userReducer,
    pdfParser: pdfParserReducer,
    riskIQ: riskIQReducer
});
