import React from 'react';
const PlanningIcon = (props) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5900_3041)">
            <path className='nav-icon' d="M18.581 2.14024L12.316 0.0512408C12.1109 -0.0170803 11.8891 -0.0170803 11.684 0.0512408L5.418 2.14024C4.42209 2.47129 3.55582 3.10774 2.94224 3.95918C2.32866 4.81062 1.99897 5.83375 2 6.88324V12.0002C2 19.5632 11.2 23.7402 11.594 23.9142C11.7218 23.971 11.8601 24.0004 12 24.0004C12.1399 24.0004 12.2782 23.971 12.406 23.9142C12.8 23.7402 22 19.5632 22 12.0002V6.88324C22.001 5.83363 21.6711 4.8104 21.0574 3.95895C20.4436 3.1075 19.5771 2.47112 18.581 2.14024ZM12 19.0002C11.8022 19.0002 11.6089 18.9416 11.4444 18.8317C11.28 18.7218 11.1518 18.5656 11.0761 18.3829C11.0004 18.2002 10.9806 17.9991 11.0192 17.8051C11.0578 17.6112 11.153 17.433 11.2929 17.2931C11.4327 17.1533 11.6109 17.058 11.8049 17.0195C11.9989 16.9809 12.2 17.0007 12.3827 17.0764C12.5654 17.152 12.7216 17.2802 12.8315 17.4447C12.9414 17.6091 13 17.8025 13 18.0002C13 18.2655 12.8946 18.5198 12.7071 18.7073C12.5196 18.8949 12.2652 19.0002 12 19.0002ZM13 14.0002C13 14.2655 12.8946 14.5198 12.7071 14.7073C12.5196 14.8949 12.2652 15.0002 12 15.0002C11.7348 15.0002 11.4804 14.8949 11.2929 14.7073C11.1054 14.5198 11 14.2655 11 14.0002V6.00024C11 5.73502 11.1054 5.48067 11.2929 5.29313C11.4804 5.1056 11.7348 5.00024 12 5.00024C12.2652 5.00024 12.5196 5.1056 12.7071 5.29313C12.8946 5.48067 13 5.73502 13 6.00024V14.0002Z" fill="#999999" />
        </g>
        <defs>
            <clipPath id="clip0_5900_3041">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default PlanningIcon;
