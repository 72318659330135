import React from 'react';
import { getDate } from '../../utility/utility';
const MaintenanceMode = (props) => {
    return (
        <>
            <div className="n-w-icn" unit-test-id='n-w-icn'></div>
            <div className="e-s">
                <div className="lgn-f-hdr" unit-test-id='lgn-f-hdr'>Under Maintenance:</div>
            </div>

            <div className="r-b-small">LYNX Logix platform is currently under maintenance to improve our service. You may experience temporary disruptions. We apologize for the inconvenience and appreciate your patience.</div>
            <div className="r-b-small m-eta" unit-test-id='m-eta'>Our ETA to be back online is {props.testMaintenanceModeEndDate ? getDate(props.testMaintenanceModeEndDate) : getDate(props.endDate)}</div>
            <div className="r-b-small">If the problem persists, don't hesitate to reach out to our dedicated support team for further assistance:</div>
            
                
            <a 
                href="mailto:sensitech.support@carrier.com"
                className={`p-w-btn pwb-margin`}
            >Contact Support</a>
            <br />
            {/* <button 
                className={`p-w-btn`}
                style={{marginTop:'4%'}}
                onClick={(e)=>{
                    e.stopPropagation();
                    localStorage.clear();
                    window.location.href = '/login';
                }}
            >Go to Login</button> */}
            </>
    )
};
export default MaintenanceMode;