import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';

const initialState = {
    uploadPDFStart: false,
    uploadPDF: {},
    uploadPDFError: false,
    getFiltersList: {brand_name: [],shipment_id:[],str_alarm:[],ptr_alarm:[], batch_id: []},
    getPDFsListStart: false,
    getPDFsList: [],
    getPDFsListError: {},
    getPDFDetailsStart: false,
    getPDFDetails: {str_alarms:[], ptr_alarms:[]},
    temp_config: {
        avg_temp: 0,
        max_temp: 0,
        min_temp: 0,
        time_above: 0,
        time_below: 0
    },
    getPDFDetailsError: {},
    meta: {},
    filterCounts: {pdfs:0, str: 0, ptr: 0},
    bulkUploadPDFStart: false,
    bulkUploadPDF: {},
    bulkUploadPDFError: false,
    getBulkPDFsListStart: false,
    getBulkPDFsList: [],
    getBulkPDFsListError: {},
    bulkMeta: {},
    pdfParserProfileListLoading: false,
    pdfParserProfileList: [],
    pdfParserProfileListData: [],
    pdfParserProfile: '',
    pdfParserProfileMessage: {},
    pdfParserProfileLoading: false,
    pdfParserProfileSaving: false,
    pdfProductProfileListLoading: false,
    pdfProductProfileList: [],
    pdfProductProfileListData: [],
    pdfProductProfile: '',
    pdfProductProfileMessage: {},
    pdfProductProfileLoading: false,
    pdfProductProfileSaving: false,
    activeIngredientsLoading: false,
    activeIngredientSaving: false,
    activeIngredients: [],
    activeIngredientsData: [],
    activeIngredientListLoading: false,
    activeIngredientList: [],
    activeIngredientListData: [],
    strengthLoading: false,
    strengthSaving: false,
    strength: [],
    strengthData: [],
    strengthListLoading: false,
    strengthList: [],
    strengthListData: [],
    pdfLaneIdListLoading: false,
    pdfLaneIdSaving: false,
    pdfLaneIdList: [],
    pdfLaneIdsData: [],
    pdfPackageTypeListLoading: false,
    pdfPackageTypeSaving: false,
    pdfPackageTypeList: [],
    pdfPackageTypeListData: [],
    activeDashboardDetails: { token : '' , host : '' , uuid : ''}
};
const USER_ID = Number(localStorage.getItem('id'));
const ROLE = localStorage.getItem('roles');
const IS_ADMIN = ROLE === 'super admin' || ROLE === 'group admin' || ROLE === 'company admin';
const uploadPDFStart = (state, action) => {
    return updateObject(state, { uploadPDF: {}, uploadPDFStart: true, uploadPDFError: false });
};
const uploadPDFSuccess = (state, action) => {
    const getPDFsList = [...state.getPDFsList];
    const fileIndex = getPDFsList.findIndex(f => f.id === action.data.data.id);
    if(fileIndex >= 0) {
        getPDFsList[fileIndex] = action.data.data;
        getPDFsList.sort((a,b) => b.uploaded_at-a.uploaded_at);
    } else {
        getPDFsList.unshift({...action.data.data});
    }    
    return updateObject(state, { getPDFsList, uploadPDF: action.data, uploadPDFStart: false, uploadPDFError: false });
};
const uploadPDFFailed = (state, action) => {
    return updateObject(state, { uploadPDFStart: false, uploadPDFError: action.data });
};
const getPDFsListStart = (state, action) => {
    return updateObject(state, { getPDFsListStart: true, getPDFsListError: false });
};
const getPDFsListSuccess = (state, action) => {
    let LIST = [];
    let filterCounts = action.data.stats || state.filterCounts;
    if (action.data?.meta?.current_page > 1) {
        LIST = [...state.getPDFsList, ...action.data.data];
    } else if (action.data?.data) {
        LIST = [...action.data.data];
    } else {
        LIST = [...state.getPDFsList];
    }
    const getBulkPDFsList = [...state.getBulkPDFsList];

    if(action.data.newPDF) {
        if(Number(action.data.newPDF.user_id) === USER_ID || IS_ADMIN) {
            const bulkFileIndex = getBulkPDFsList.findIndex(f => f.id === action.data.newPDF.zip_upload_id);
            const fileIndex = LIST.findIndex(f => f.id === action.data.newPDF.id);
            if(fileIndex >= 0) {
                LIST[fileIndex] = action.data.newPDF;
                LIST.sort((a,b) => b.uploaded_at-a.uploaded_at);
            } else {
                LIST.unshift(action.data.newPDF);
            }
            LIST.length = LIST.length > 20 ? 20 : LIST.length;

            if(action.data.newPDF.status === 'PROCESSED' || action.data.newPDF.status === 'ERROR') {
                filterCounts = action.data.newPDF.stats;
                if(IS_ADMIN && action.data.newPDF.company_stats?.pdfs) {
                    filterCounts = action.data.newPDF.company_stats;
                }
                if(Object.hasOwn(action.data.newPDF, 'error_file_count')) {
                    getBulkPDFsList[bulkFileIndex].error_file_count = action.data.newPDF.error_file_count;
                }
                if(Object.hasOwn(action.data.newPDF, 'processed_file_count')) {
                    getBulkPDFsList[bulkFileIndex].processed_file_count = action.data.newPDF.processed_file_count;
                }
            }

            if(action.data.newPDF.zip_upload_id && !(Object.hasOwn(action.data.newPDF, 'error_file_count') || Object.hasOwn(action.data.newPDF, 'processed_file_count'))) {
                if(action.data.newPDF.status === 'PROCESSED') {
                    getBulkPDFsList[bulkFileIndex].processed_file_count++;
                    if(getBulkPDFsList[bulkFileIndex].processed_file_count > getBulkPDFsList[bulkFileIndex].file_count) {
                        getBulkPDFsList[bulkFileIndex].processed_file_count = getBulkPDFsList[bulkFileIndex].file_count;
                    }
                } else if (action.data.newPDF.zip_upload_id === 'ERROR') {
                    getBulkPDFsList[bulkFileIndex].error_file_count++;
                    if(getBulkPDFsList[bulkFileIndex].error_file_count > getBulkPDFsList[bulkFileIndex].file_count) {
                        getBulkPDFsList[bulkFileIndex].error_file_count = getBulkPDFsList[bulkFileIndex].file_count - getBulkPDFsList[bulkFileIndex].processed_file_count;
                    }
                }
                if(Object.hasOwn(getBulkPDFsList[bulkFileIndex], 'processed_file_count') && Object.hasOwn(getBulkPDFsList[bulkFileIndex], 'error_file_count') && (getBulkPDFsList[bulkFileIndex].processed_file_count+getBulkPDFsList[bulkFileIndex].error_file_count) === getBulkPDFsList[bulkFileIndex].file_count && getBulkPDFsList[bulkFileIndex].status !== 'PROCESSED'){
                    getBulkPDFsList[bulkFileIndex].status = 'PROCESSED';
                }
            }
        }
    }
    
    return updateObject(state, {
        getPDFsList: LIST,
        getBulkPDFsList,
        filterCounts,
        getFiltersList: action.data.filtersList || action.data.getFiltersList || state.getFiltersList,
        meta: action.data.meta || state.meta,
        getPDFsListStart: false, 
        getPDFsListError: false,
        getPDFDetails: action.data?.data?.length === 0 ? {alarms:[]} : state.getPDFDetails
    });
};
const getPDFsListFailed = (state, action) => {
    return updateObject(state, { getPDFsListStart: false, getPDFsListError: action.data });
};
const getPDFDetailsStart = (state, action) => {
    return updateObject(state, { getPDFDetailsStart: true, getPDFDetailsError: false });
};
const getPDFDetailsSuccess = (state, action) => {
    return updateObject(state, { 
        getPDFDetails: action.data.data[0] || {}, 
        getPDFDetailsStart: false, 
        getPDFDetailsError: false 
    });
};
const getPDFDetailsFailed = (state, action) => {
    return updateObject(state, { getPDFDetailsStart: false, getPDFDetailsError: action.data });
};
const getFiltersListStart = (state, action) => {
    return updateObject(state, { getFiltersListStart: true, getFiltersListError: false });
};
const getFiltersListSuccess = (state, action) => {
    return updateObject(state, { 
        getFiltersList: action.data.data, 
        getFiltersListStart: false, 
        getFiltersListError: false,
    });
};
const getFiltersListFailed = (state, action) => {
    return updateObject(state, { getFiltersListStart: false, getFiltersListError: action.data });
};
const bulkUploadPDFStart = (state, action) => {
    return updateObject(state, { bulkUploadPDF: {}, bulkUploadPDFStart: true, bulkUploadPDFError: false });
};
const bulkUploadPDFSuccess = (state, action) => {
    const getBulkPDFsList = [...state.getBulkPDFsList];
    const fileIndex = getBulkPDFsList.findIndex(f => f.id === action.data.data.id);
    if(fileIndex >= 0) {
        getBulkPDFsList[fileIndex] = action.data.data;
        getBulkPDFsList.sort((a,b) => b.uploaded_at-a.uploaded_at);
    } else {
        const data = {...action.data.data};
        getBulkPDFsList.unshift(data);
    }    
    return updateObject(state, { getBulkPDFsList, bulkUploadPDF: action.data, bulkUploadPDFStart: false, bulkUploadPDFError: false });
};
const bulkUploadPDFFailed = (state, action) => {
    return updateObject(state, { bulkUploadPDFStart: false, bulkUploadPDFError: action.data });
};
const getBulkPDFsListStart = (state, action) => {
    return updateObject(state, { getBulkPDFsListStart: true, getBulkPDFsListError: false });
};
const getBulkPDFsListSuccess = (state, action) => {
    let LIST = [];
    if (action.data?.meta?.current_page > 1) {
        LIST = [...state.getBulkPDFsList, ...action.data.data];
    } else if (action.data?.data) {
        LIST = [...action.data.data];
    } else {
        LIST = [...state.getBulkPDFsList];
    }

    if(action.data.newPDF) {
        if(Number(action.data.newPDF.user_id) === USER_ID || IS_ADMIN) {
            const fileIndex = LIST.findIndex(f => f.id === action.data.newPDF.id);
            if(fileIndex >= 0) {
                LIST[fileIndex] = action.data.newPDF;
                LIST.sort((a,b) => b.uploaded_at-a.uploaded_at);
            } else {
                LIST.unshift(action.data.newPDF);
            }
            LIST.length = LIST.length > 20 ? 20 : LIST.length;

        }
    }
    
    return updateObject(state, {
        getBulkPDFsList: LIST,
        bulkMeta: action.data.meta || state.bulkMeta,
        getBulkPDFsListStart: false, 
        getBulkPDFsListError: false,
    });
};
const getBulkPDFsListFailed = (state, action) => {
    return updateObject(state, { getBulkPDFsListStart: false, getBulkPDFsListError: action.data });
};

const getPdfParserProfileListStart = (state, action) => {
    return updateObject(state, {
        pdfParserProfileListLoading: true
    });
};

const getPdfParserProfileListSuccess = (state, action) => {
    const data = action.data || state.pdfParserProfileList;
    const DATA = data?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    const DUP_DATA = state.pdfParserProfileListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    if(action.deleteAction) {
        const profileIndex = DUP_DATA.findIndex(p => p.id === action.deleteAction);
        DUP_DATA.splice(profileIndex, 1);
    }
    return updateObject(state, {
        pdfParserProfileListLoading: false,
        pdfParserProfileList: DATA,
        pdfParserProfileListData: action.clear || action.deleteAction ? DUP_DATA : DATA,
    });
};

const getPdfParserProfileStart = (state, action) => {
    return updateObject(state, {
        pdfParserProfileLoading: true
    });
};

const getPdfParserProfileSuccess = (state, action) => {
    return updateObject(state, {
        pdfParserProfileLoading: false,
        pdfParserProfile: action.data,
    });
};

const savePdfParserProfileSaving = (state, action) => {
    return updateObject(state, {
        pdfParserProfileSaving: true
    });
};
const savePdfParserProfileSuccess = (state, action) => {
    return updateObject(state, { 
        updatePdfParserProfileMessage: action.message,
        pdfParserProfileSaving: false, 
        error: action.error
    });
};

const deletePdfParserProfileSuccess = (state, action) => {
    return updateObject(state, { 
        deletePdfParserProfileMessage: action.message, 
        pdfParserProfileSaving: false,
        error: action.error
    });
};

const getPdfProductProfileListStart = (state, action) => {
    return updateObject(state, {
        pdfProductProfileListLoading: true
    });
};

const getPdfProductProfileListSuccess = (state, action) => {
    const data = action.data || state.pdfProductProfileList;
    const DATA = data?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    const DUP_DATA = state.pdfProductProfileListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    if(action.deleteAction) {
        const profileIndex = DUP_DATA.findIndex(p => p.id === action.deleteAction);
        DUP_DATA.splice(profileIndex, 1);
    }
    if(action.updatedObj && action.status === 'update') {
        DATA.map(product => {
            if(product.active_ingredient_id === action.updatedObj.id) {
                product.active_ingredient_name[0] = action.updatedObj.name;
                product.active_ingredient_color = action.updatedObj.color;
            }
            return true;
        });
        
        DUP_DATA.map(product => {
            if(product.active_ingredient_id === action.updatedObj.id) {
                product.active_ingredient_name[0] = action.updatedObj.name;
                product.active_ingredient_color = action.updatedObj.color;
            }
            return true;
        });
    }
    // if(action.updatedObj && action.status === 'add') {
    //     DATA.map(product => {
    //         if(product.id === action.updatedObj.product_id) {
    //             product.active_ingrdient.name = action.updatedObj.name;
    //             product.active_ingrdient.color = action.updatedObj.color;
    //             product.active_ingrdient.id = action.updatedObj.id;
    //         }
    //     });
    //     DUP_DATA.map(product => {
    //         if(product.active_ingrdient.id === action.updatedObj.id) {
    //             product.active_ingrdient.name = action.updatedObj.name;
    //             product.active_ingrdient.color = action.updatedObj.color;
    //             product.active_ingrdient.id = action.updatedObj.id;
    //         }
    //     });
    // }
    //console.log('getPdfProductProfileListSuccess action: ', action);
    if(action.updatedObj && action.status === 'delete') {
        DATA.map(product => {
            if(product.active_ingredient_id === action.updatedObj.id) {
                product.active_ingrdient.name = '';
                product.active_ingrdient.color = '';
            }
            return true;
        });
        DUP_DATA.map(product => {
            if(product.active_ingredient_id === action.updatedObj.id) {
                product.active_ingrdient.name = '';
                product.active_ingrdient.color = '';
            }
            return true;
        });
    }

    return updateObject(state, {
        pdfProductProfileListLoading: false,
        pdfProductProfileList: DATA,
        pdfProductProfileListData: action.clear || action.deleteAction ? DUP_DATA : DATA,
    });
};

const getPdfProductProfileStart = (state, action) => {
    return updateObject(state, {
        pdfProductProfileLoading: true
    });
};

const getPdfProductProfileSuccess = (state, action) => {
    console.log('-----pdfProductProfile: ', action.data);
    return updateObject(state, {
        pdfProductProfileLoading: false,
        pdfProductProfile: action.data,
    });
};

const savePdfProductProfileSaving = (state, action) => {
    return updateObject(state, {
        pdfProductProfileSaving: true
    });
};
const savePdfProductProfileSuccess = (state, action) => {
    return updateObject(state, { 
        updatePdfProductProfileMessage: action.message,
        pdfProductProfileSaving: false, 
        error: action.error
    });
};

const deletePdfProductProfileSuccess = (state, action) => {
    return updateObject(state, { 
        deletePdfProductProfileMessage: action.message, 
        pdfProductProfileSaving: false,
        error: action.error
    });
};

const getActiveIngredientListStart = (state, action) => {
    return updateObject(state, {
        activeIngredientListLoading: true
    });
};

const getActiveIngredientListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.activeIngredientListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        activeIngredientListLoading: false,
        activeIngredientList: DATA,
        //activeIngredientListData: action.clear ? DUP_DATA : DATA,
    });
};


const getActiveIngredientStart = (state, action) => {
    return updateObject(state, {
        activeIngredientsLoading: true
    });
};

const getActiveIngredientSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        activeIngredientsLoading: false,
        activeIngredients: DATA,
    });
};

const saveActiveIngredientSaving = (state, action) => {
    return updateObject(state, {
        activeIngredientSaving: true
    });
};
const saveActiveIngredientSuccess = (state, action) => {
    return updateObject(state, { 
        updateActiveIngredientMessage: action.message, 
        activeIngredientSaving: false, 
        error: action.error
    });
};

const deleteActiveIngredientSuccess = (state, action) => {
    return updateObject(state, { 
        deleteActiveIngredientMessage: action.message, 
        activeIngredientSaving: false,
        error: action.error
    });
};

const getStrengthListStart = (state, action) => {
    return updateObject(state, {
        strengthListLoading: true
    });
};

const getStrengthListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.strengthListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        strengthListLoading: false,
        strengthList: DATA,
        //strengthListData: action.clear ? DUP_DATA : DATA,
    });
};


const getStrengthStart = (state, action) => {
    return updateObject(state, {
        strengthLoading: true
    });
};

const getStrengthSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        strengthLoading: false,
        strength: DATA,
    });
};

const saveStrengthSaving = (state, action) => {
    return updateObject(state, {
        strengthSaving: true
    });
};
const saveStrengthSuccess = (state, action) => {
    return updateObject(state, { 
        updateStrengthMessage: action.message, 
        strengthSaving: false, 
        error: action.error
    });
};

const deleteStrengthSuccess = (state, action) => {
    return updateObject(state, { 
        deleteStrengthMessage: action.message, 
        strengthSaving: false,
        error: action.error
    });
};

const getPdfLaneIdListStart = (state, action) => {
    return updateObject(state, {
        pdfLaneIdListLoading: true
    });
};

const getPdfLaneIdListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.pdfLaneIdListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        pdfLaneIdListLoading: false,
        pdfLaneIdList: DATA,
        //pdfLaneIdListData: action.clear ? DUP_DATA : DATA,
    });
};

const getPdfLaneIdStart = (state, action) => {
    return updateObject(state, {
        pdfLaneIdLoading: true
    });
};

const getPdfLaneIdSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        pdfLaneIdLoading: false,
        pdfLaneId: DATA,
    });
};

const savePdfLaneIdSaving = (state, action) => {
    return updateObject(state, {
        pdfLaneIdSaving: true
    });
};
const savePdfLaneIdSuccess = (state, action) => {
    return updateObject(state, { 
        updatePdfLaneIdMessage: action.message, 
        pdfLaneIdSaving: false, 
        error: action.error
    });
};

const deletePdfLaneIdSuccess = (state, action) => {
    return updateObject(state, { 
        deletePdfLaneIdMessage: action.message, 
        pdfLaneIdSaving: false,
        error: action.error
    });
};

const getPdfPackageTypeListStart = (state, action) => {
    return updateObject(state, {
        pdfPackageTypeListLoading: true
    });
};

const getPdfPackageTypeListSuccess = (state, action) => {
    let DATA = action.data;
    if(action.clear && action.clear.id) {
        DATA.unshift(action.clear);
    } else if(action.index){
        DATA.splice(action.index, 1);
    }
    //DATA = DATA?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    //const DUP_DATA = state.pdfPackageTypeListData?.sort((a, b) => a.default === 1 ? -1 : 1) || [];
    
    return updateObject(state, {
        pdfPackageTypeListLoading: false,
        pdfPackageTypeList: DATA,
        //pdfPackageTypeListData: action.clear ? DUP_DATA : DATA,
    });
};


const getPdfPackageTypeStart = (state, action) => {
    return updateObject(state, {
        pdfPackageTypeLoading: true
    });
};

const getPdfPackageTypeSuccess = (state, action) => {
    let DATA = action.data;
    return updateObject(state, {
        pdfPackageTypeLoading: false,
        pdfPackageType: DATA,
    });
};

const savePdfPackageTypeSaving = (state, action) => {
    return updateObject(state, {
        pdfPackageTypeSaving: true
    });
};
const savePdfPackageTypeSuccess = (state, action) => {
    return updateObject(state, { 
        updatePdfPackageTypeMessage: action.message, 
        pdfPackageTypeSaving: false, 
        error: action.error
    });
};

const deletePdfPackageTypeSuccess = (state, action) => {
    return updateObject(state, { 
        deletePdfPackageTypeMessage: action.message, 
        pdfPackageTypeSaving: false,
        error: action.error
    });
};

const getDashboardDetailsSuccess = (state, action) => {
    return updateObject(state, { 
        activeDashboardDetails: action.data
    });
};

const pdfReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPLOAD_PDF_START:
            return uploadPDFStart(state, action);
        case actionTypes.UPLOAD_PDF_SUCCESS:
            return uploadPDFSuccess(state, action);
        case actionTypes.UPLOAD_PDF_FAILED:
            return uploadPDFFailed(state, action);
        case actionTypes.GET_PDF_LIST_START:
            return getPDFsListStart(state, action);
        case actionTypes.GET_PDF_LIST_SUCCESS:
            return getPDFsListSuccess(state, action);
        case actionTypes.GET_PDF_LIST_FAILED:
            return getPDFsListFailed(state, action);
        case actionTypes.GET_PDF_DETAILS_START:
            return getPDFDetailsStart(state, action);
        case actionTypes.GET_PDF_DETAILS_SUCCESS:
            return getPDFDetailsSuccess(state, action);
        case actionTypes.GET_PDF_DETAILS_FAILED:
            return getPDFDetailsFailed(state, action);
        case actionTypes.GET_FILTERS_LIST_START:
            return getFiltersListStart(state, action);
        case actionTypes.GET_FILTERS_LIST_SUCCESS:
            return getFiltersListSuccess(state, action);
        case actionTypes.GET_FILTERS_LIST_FAILED:
            return getFiltersListFailed(state, action);
        case actionTypes.BULK_UPLOAD_PDF_START:
            return bulkUploadPDFStart(state, action);
        case actionTypes.BULK_UPLOAD_PDF_SUCCESS:
            return bulkUploadPDFSuccess(state, action);
        case actionTypes.BULK_UPLOAD_PDF_FAILED:
            return bulkUploadPDFFailed(state, action);
        case actionTypes.GET_BULK_PDF_LIST_START:
            return getBulkPDFsListStart(state, action);
        case actionTypes.GET_BULK_PDF_LIST_SUCCESS:
            return getBulkPDFsListSuccess(state, action);
        case actionTypes.GET_BULK_PDF_LIST_FAILED:
            return getBulkPDFsListFailed(state, action);
        case actionTypes.GET_PDF_PARSER_PROFILE_LIST_START:
            return getPdfParserProfileListStart(state, action);
        case actionTypes.GET_PDF_PARSER_PROFILE_LIST_SUCCESS:
            return getPdfParserProfileListSuccess(state, action);
        case actionTypes.GET_PDF_PARSER_PROFILE_START:
            return getPdfParserProfileStart(state, action);
        case actionTypes.GET_PDF_PARSER_PROFILE_SUCCESS:
            return getPdfParserProfileSuccess(state, action);
        case actionTypes.SAVE_PDF_PARSER_PROFILE_START:
            return savePdfParserProfileSaving(state, action);
        case actionTypes.SAVE_PDF_PARSER_PROFILE_SUCCESS:
            return savePdfParserProfileSuccess(state, action);
        case actionTypes.DELETE_PDF_PARSER_PROFILE_SUCCESS:
            return deletePdfParserProfileSuccess(state, action);
        case actionTypes.GET_PDF_PRODUCT_PROFILE_LIST_START:
            return getPdfProductProfileListStart(state, action);
        case actionTypes.GET_PDF_PRODUCT_PROFILE_LIST_SUCCESS:
            return getPdfProductProfileListSuccess(state, action);
        case actionTypes.GET_PDF_PRODUCT_PROFILE_START:
            return getPdfProductProfileStart(state, action);
        case actionTypes.GET_PDF_PRODUCT_PROFILE_SUCCESS:
            return getPdfProductProfileSuccess(state, action);
        case actionTypes.SAVE_PDF_PRODUCT_PROFILE_START:
            return savePdfProductProfileSaving(state, action);
        case actionTypes.SAVE_PDF_PRODUCT_PROFILE_SUCCESS:
            return savePdfProductProfileSuccess(state, action);
        case actionTypes.DELETE_PDF_PRODUCT_PROFILE_SUCCESS:
            return deletePdfProductProfileSuccess(state, action);
        case actionTypes.GET_ACTIVE_INGREDIENT_LIST_START:
            return getActiveIngredientListStart(state, action);
        case actionTypes.GET_ACTIVE_INGREDIENT_LIST_SUCCESS:
            return getActiveIngredientListSuccess(state, action);
        case actionTypes.GET_ACTIVE_INGREDIENT_START:
            return getActiveIngredientStart(state, action);
        case actionTypes.GET_ACTIVE_INGREDIENT_SUCCESS:
            return getActiveIngredientSuccess(state, action);
        case actionTypes.SAVE_ACTIVE_INGREDIENT_START:
            return saveActiveIngredientSaving(state, action);
        case actionTypes.SAVE_ACTIVE_INGREDIENT_SUCCESS:
            return saveActiveIngredientSuccess(state, action);
        case actionTypes.DELETE_ACTIVE_INGREDIENT_SUCCESS:
            return deleteActiveIngredientSuccess(state, action);
        case actionTypes.GET_PDF_LANE_ID_LIST_START:
            return getPdfLaneIdListStart(state, action);
        case actionTypes.GET_PDF_LANE_ID_LIST_SUCCESS:
            return getPdfLaneIdListSuccess(state, action);
        case actionTypes.GET_PDF_LANE_ID_START:
            return getPdfLaneIdStart(state, action);
        case actionTypes.GET_PDF_LANE_ID_SUCCESS:
            return getPdfLaneIdSuccess(state, action);
        case actionTypes.SAVE_PDF_LANE_ID_START:
            return savePdfLaneIdSaving(state, action);
        case actionTypes.SAVE_PDF_LANE_ID_SUCCESS:
            return savePdfLaneIdSuccess(state, action);
        case actionTypes.DELETE_PDF_LANE_ID_SUCCESS:
            return deletePdfLaneIdSuccess(state, action);
        case actionTypes.GET_PDF_PACKAGE_TYPE_LIST_START:
            return getPdfPackageTypeListStart(state, action);
        case actionTypes.GET_PDF_PACKAGE_TYPE_LIST_SUCCESS:
            return getPdfPackageTypeListSuccess(state, action);
        case actionTypes.GET_PDF_PACKAGE_TYPE_START:
            return getPdfPackageTypeStart(state, action);
        case actionTypes.GET_PDF_PACKAGE_TYPE_SUCCESS:
            return getPdfPackageTypeSuccess(state, action);
        case actionTypes.SAVE_PDF_PACKAGE_TYPE_START:
            return savePdfPackageTypeSaving(state, action);
        case actionTypes.SAVE_PDF_PACKAGE_TYPE_SUCCESS:
            return savePdfPackageTypeSuccess(state, action);
        case actionTypes.DELETE_PDF_PACKAGE_TYPE_SUCCESS:
            return deletePdfPackageTypeSuccess(state, action);
        case actionTypes.GET_STRENGTH_LIST_START:
            return getStrengthListStart(state, action);
        case actionTypes.GET_STRENGTH_LIST_SUCCESS:
            return getStrengthListSuccess(state, action);
        case actionTypes.GET_STRENGTH_START:
            return getStrengthStart(state, action);
        case actionTypes.GET_STRENGTH_SUCCESS:
            return getStrengthSuccess(state, action);
        case actionTypes.SAVE_STRENGTH_START:
            return saveStrengthSaving(state, action);
        case actionTypes.SAVE_STRENGTH_SUCCESS:
            return saveStrengthSuccess(state, action);
        case actionTypes.DELETE_STRENGTH_SUCCESS:
            return deleteStrengthSuccess(state, action);
        case actionTypes.GET_SUPERSET_DASHBOARD_DETAILS_SUCCESS:
            return getDashboardDetailsSuccess(state, action);
        default:
            return state;
    }
};

export default pdfReducer;