import React from 'react';
const UserProfileIcon = (props) => {
    return (<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_3450_12534)">
            <path className='profile-pop-icon' d="M10.0007 4C10.0007 1.794 8.20667 0 6.00067 0C3.79467 0 2.00067 1.794 2.00067 4C2.00067 6.206 3.79467 8 6.00067 8C8.20667 8 10.0007 6.206 10.0007 4ZM6.00067 6C4.898 6 4.00067 5.10267 4.00067 4C4.00067 2.89733 4.898 2 6.00067 2C7.10333 2 8.00067 2.89733 8.00067 4C8.00067 5.10267 7.10333 6 6.00067 6ZM5.99267 10.2093C6.06133 10.7573 5.67267 11.2567 5.124 11.3253C3.37267 11.544 2 13.158 2 15C2 15.5527 1.55267 16 1 16C0.447333 16 0 15.5527 0 15C0 12.1673 2.142 9.682 4.876 9.34133C5.42133 9.27067 5.92333 9.66133 5.992 10.21L5.99267 10.2093ZM15.334 12.332L14.9087 12.0813C14.9647 11.84 15.0007 11.5913 15.0007 11.3327C15.0007 11.074 14.9647 10.826 14.9087 10.584L15.334 10.3333C15.81 10.0533 15.9687 9.44 15.688 8.964C15.4073 8.488 14.7947 8.33067 14.3187 8.61L13.8867 8.86467C13.538 8.55067 13.124 8.31467 12.6667 8.16933V7.666C12.6667 7.11333 12.2193 6.666 11.6667 6.666C11.114 6.666 10.6667 7.11333 10.6667 7.666V8.16933C10.2093 8.31467 9.79533 8.55067 9.44667 8.86467L9.01467 8.61C8.53933 8.33133 7.92667 8.488 7.64533 8.964C7.36533 9.44 7.52333 10.0527 7.99933 10.3333L8.42467 10.584C8.36867 10.8253 8.33267 11.074 8.33267 11.3327C8.33267 11.5913 8.36867 11.8393 8.42467 12.0813L7.99933 12.332C7.52333 12.612 7.36467 13.2253 7.64533 13.7013C7.832 14.018 8.16533 14.194 8.508 14.194C8.68067 14.194 8.85533 14.1493 9.01467 14.0553L9.44667 13.8007C9.79533 14.1147 10.2093 14.3507 10.6667 14.496V14.9993C10.6667 15.552 11.114 15.9993 11.6667 15.9993C12.2193 15.9993 12.6667 15.552 12.6667 14.9993V14.496C13.124 14.3507 13.538 14.1147 13.8867 13.8007L14.3187 14.0553C14.478 14.1493 14.6527 14.194 14.8253 14.194C15.168 14.194 15.5013 14.018 15.688 13.7013C15.968 13.2253 15.81 12.6127 15.334 12.332ZM11.6673 12.3327C11.116 12.3327 10.6673 11.884 10.6673 11.3327C10.6673 10.7813 11.116 10.3327 11.6673 10.3327C12.2187 10.3327 12.6673 10.7813 12.6673 11.3327C12.6673 11.884 12.2187 12.3327 11.6673 12.3327Z" fill="#333333" />
        </g>
        <defs>
            <clipPath id="clip0_3450_12534">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default UserProfileIcon;
