import React, { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { googleAnalyticActions } from '../../../init-ga';
import PerfectScrollbar from '../../ScrollBarHOC/scrollBarHOC';
import { isWithinRange } from '../../../utility/utility';
import AvatarIcon from '../../../assets/images/lynx/avatar';
import QuestionIcon from '../../../assets/images/lynx/question';
import LynxLogo from '../../../assets/images/lynx/navbar-lynx-logo.svg';
import PdfIcon from '../../../assets/images/lynx/navbar-pdf-icon';
import PerformanceIcon from '../../../assets/images/lynx/navbar-performance-icon';
import PlanningIcon from '../../../assets/images/lynx/navbar-planning-icon';
import RiskIcon from '../../../assets/images/lynx/navbar-risk-icon';
import SettingsIcon from '../../../assets/images/lynx/navbar-settings-icon';
import CarrierLynxLogo from '../../../assets/images/lynx/carrier_lynx_logo.svg';
import ContactSupportIcon from '../../../assets/images/lynx/redesign-contact-support';
import UserProfileIcon from '../../../assets/images/lynx/redesign-user-profile-icon';
import DocIcon from '../../../assets/images/lynx/redesign-stability-form';
import LogoutIcon from '../../../assets/images/lynx/redesign-logout-icon';
// The desired locales.
import MaintenanceMode from 'components/Auth/MaintenanceMode';
import ServerError from 'components/Auth/ServerError';
import SessionExpired from 'components/Auth/SessionExpired';
import AlertMessage from "components/AlertMessage/AlertMessage";

const headerLabels = require(`../../../config/${process.env.REACT_APP_CUSTOMER_ID}/headerLabels.js`).default;
const apis = require(`../../../config/${process.env.REACT_APP_CUSTOMER_ID}/apis.js`).default;

const ENV = parseInt(process.env.REACT_APP_CUSTOMER_ID,10);
const MobileConfig = {
    mobile: window.screen.width < 1024 ? true : false
}

const TITLE_MAPPING = {
    'riskiq' : headerLabels.lane_risk_score.label,
    'active_dashboard' : headerLabels.lane_risk_score.active_dashboard_link,
    'temperature_dashboard' : headerLabels.pdf_parser.passive_dashboard,
    'riskplanning' : headerLabels.risk_scenario_planning.label,
    'pdfparser' : headerLabels.pdf_parser.label,
    'groups': headerLabels.config.sub_menu.groups,
    'companies' : headerLabels.config.sub_menu.companies,
    'groups' : 'Groups',
    'users' : headerLabels.config.sub_menu.users,
    'config_parserConfig' : headerLabels.config.sub_menu.pdf_upload_config,
    'config_productConfig' : headerLabels.config.sub_menu.product_type_config,
    'profile' : 'Manage Your Account',
    'terms' : 'Terms of Use',
    'privacy' : 'Privacy Notice',
    'undefined' : 'LYNX'
}

const ROLES = localStorage.getItem('roles');
const userId = localStorage.getItem('id');
let trackUserSession = null;

window.onbeforeunload = () => {
    console.log('------->before unload operations...HEADER..');
    if (window.location.pathname.indexOf('riskiq') === -1) {
        localStorage.removeItem('lane_id');
    }
};

const Header = (props) => {

    const dispatch = useDispatch();
    const ud = JSON.parse(localStorage.getItem('userDetails'))||{firstname:'',lastname:''};

    const checkLogout = useSelector(state => state.company.logout);
    const serverError = useSelector(state => state.company.serverError);
    const networkError = useSelector(state => state.company.networkError);
    const maintenanceMode = useSelector(state => state.login.maintenanceMode);

    const [initials, setInitials] = useState(`${ud.firstname[0]}${ud.lastname[0]}`);
    const [userName, setUserName] = useState(`${ud.firstname} ${ud.lastname}`);
    const [userEmail, setUserEmail] = useState(ud.email);
    const [showContactSupport, setShowContactSupport] = useState(false);
    const [showExpandedView, setShowExpandedView] = useState(false);
    const [runOnce, setRunOnce] = useState('');
    const [showAlertMessage, setShowAlertMessage] = useState(true);
    const [showUserProfilePop, setShowUserProfilePop] = useState(false);
    const [childsVisible, setChildsVisible] = useState({lane_performance: false, administration: false});
    const { getAccessTokenSilently, logout } = useAuth0();

    useEffect(() => {
        let URL = window.location.pathname.substring(1).replace('/', '_');
        if(URL.indexOf('riskiq')>=0) URL = 'riskiq';
        if(URL.indexOf('companies')>=0) URL = 'companies';
        if(URL.indexOf('groups')>=0) URL = 'groups';
        if(URL.indexOf('riskplanning')>=0) URL = 'riskplanning';
        document.title = TITLE_MAPPING[URL];
    }, []);

    useEffect(() => {
        if(checkLogout) {
            sessionStorage.setItem("sessionExpired",true);
            logoutWithRedirect();
        }
    }, [checkLogout]);

    useEffect(() => {
        const lastActivityTime = localStorage.getItem("last_activity");
        const latestActivityTime = localStorage.getItem("latest_activity");
        const expiryTimeDuration = localStorage.getItem("token_expiry_duration");
        const lastActiveStatusTime = lastActivityTime ? lastActivityTime : latestActivityTime;
        if(isWithinRange(expiryTimeDuration, lastActiveStatusTime)) {
            // console.log("**********extending*************");
            //console.log("**********functionParams*************" , expiryTimeDuration, lastActiveStatusTime);
            (async function extendUserSession() {
                const accessToken = await getAccessTokenSilently({ cacheMode: 'off' });
                localStorage.setItem("access_token", accessToken);
                //console.log("GOT the new token and setting timeout to redirect logout at:  ", new Date(new Date().getTime()+10*60*1000), ' ============== ', new Date, ' | ', Number(expiryTimeDuration)*1000);
                if(trackUserSession) clearTimeout(trackUserSession);
                trackUserSession = setTimeout(() => {
                    //console.log('Loggingout after timeout: ', new Date());
                    sessionStorage.setItem("sessionExpired",true);
                    clearTimeout(trackUserSession);
                    logoutWithRedirect();
                }, Number(expiryTimeDuration)*1000);
                // console.log("**********extendUserSession*************");
            })();
        }
    }, [runOnce]);

    const recordTokenExpiry = (token) => {
        const parts = token.split('.');
        if (parts.length !== 3) {
            throw new Error('Invalid JWT token format');
        }

        const decodedHeader = atob(parts[0]);
        const decodedPayload = atob(parts[1]);
        const signature = parts[2]; 

        const header = JSON.parse(decodedHeader);
        const payload = JSON.parse(decodedPayload);

        localStorage.setItem("token_expiry", payload.exp)
    }

    const logoutWithRedirect = () => {
        localStorage.clear();
        clearCookies();
        logout({
            logoutParams: {
                returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
                // returnTo: 'http://localhost:3000',
            }
        });
    }

    const clearCookies = () => {
        var allCookies = document.cookie.split(';');
        for (var i = 0; i < allCookies.length; i++) {
            document.cookie = allCookies[i] + "=; expires=" + new Date(0).toUTCString();
        }
    }

    const redirectToLandingPage = () => {
        if(ENV === 0){
            window.location.href = '/dashboard';
        } else{
            if(localStorage.getItem('landingPage') === "risk_iq"){
                window.location.href = '/riskiq';
            }else if(localStorage.getItem('landingPage') === "pdf_parser"){
                window.location.href = '/pdfParser';
            }else{
                window.location.href = '/profile';
            }
        }
    };

    const hrefMethod = (e,href) => {
        e.stopPropagation();
        e.preventDefault();
        window.location.href = href;
    };

    const handleUserProfilePopup = (e) => {
        const elementClassName = e.target.className;
        if (
            e.target && elementClassName && typeof(elementClassName) === 'string' && (
                (elementClassName.indexOf('usr-prof-b') >= 0 && showUserProfilePop)
            )
        ) {
            setTimeout(() => {
                //console.log('hiding.......if....');
                setShowUserProfilePop(false);
                document.body.removeEventListener('click', handleUserProfilePopup);
                return false;
            }, 0);
        } else {
            setTimeout(() => {
                //console.log('----------hiding else.....');
                setShowUserProfilePop(false);
                document.body.removeEventListener('click', handleUserProfilePopup);
                return false;
            }, 0);
        }
    };
    const toggleUserProfile = (e) => {
        e.stopPropagation();
        
        const targetClass = e.target.className;
        if (targetClass.indexOf('usr-prof-b') >= 0) {
            //console.log('clicked on routes-fltr value...');
            if (showUserProfilePop) {
                //console.log('already showing........');
                document.body.removeEventListener('click', handleUserProfilePopup);
            } else {
                document.body.addEventListener('click', handleUserProfilePopup);
                setShowUserProfilePop(true);
            }
        } else {
            return false;
        }
    };

    const toggleChildNav = (e, active_nav, obj) => {
        e.stopPropagation();
        if(showExpandedView) {} else return false;
        const _childsVisible = {...obj};
        if(childsVisible[active_nav]){ _childsVisible[active_nav] = false; }
        setChildsVisible(_childsVisible);
    };

    const pathname = window.location.pathname;

    return (
        <>
            {
                showAlertMessage?
                <AlertMessage
                    show={showAlertMessage}
                    status={'info'}
                    content={'Please, access Logix system from your tablet or laptop for better experience.'}
                    confirmText={'Ok, got it'}
                    onClose={() => {
                        setShowAlertMessage(false);
                    }}
                />
                : <></>
            }
            <div className="redesign-nav-bar">
                <div className={`redesign-nav-bar-1 ${showExpandedView ? 'ex': ''}`}>
                    <div className={`redesign-nav-bar-p1 ${showExpandedView ? 'ex': ''}`}>
                        <div className={`redesign-nav-logo ${showExpandedView ? 'ex' : ''}`}>
                            <img onClick={(e)=>{
                                e.stopPropagation();
                                googleAnalyticActions.addEvent('Dashboard', 'CARRIER Homepage');
                                redirectToLandingPage();
                            }} src={LynxLogo} alt="lynx_icon"/>
                            <span className={`redesign-navbar-hdr ${showExpandedView ? 'ex' : ''}`}>Carrier Lynx Management System</span>
                        </div>
                        <div className="redesign-nav-links">
                            <ul className="redesign-nav-list">
                                {
                                    parseInt(localStorage.getItem('riskIQ')) ?
                                    <li className={`${pathname.indexOf('riskiq') > 0 || pathname==="/" ? 'a' : ''} ${showExpandedView ? 'ex': 't-tip'}`} onClick={(e) => {hrefMethod(e,'/riskiq')}}>
                                        <a><RiskIcon alt="risk_icon" className="icn" /></a>
                                        <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': 't-txt'}`}>Lane Risk Assessment</div>
                                    </li>
                                    : <></>
                                }
                                { 
                                    parseInt(localStorage.getItem('riskIQ')) || parseInt(localStorage.getItem('pdfParser')) ?
                                    <>
                                    <li className={`has-child-nodes ${pathname.indexOf('active_dashboard') > 0 || pathname.indexOf('temperature_dashboard') > 0 ? 'a' : ''} ${showExpandedView ? 'ex db': ''} show-nav${childsVisible.lane_performance?' disp-childs':''}`} onClick={(e) => {toggleChildNav(e, 'lane_performance', {lane_performance: true, administration: false});}}>
                                        <div className="nav-arr">       
                                            <a onClick={(e) => {hrefMethod(e,localStorage.getItem('riskIQ') ?  '/active_dashboard' : '/temperature_dashboard')}}><PerformanceIcon alt="performance_icon" className="icn" /></a>
                                            <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': ''}`}>Lane Performance </div>
                                            <div className={`dwn-arr ${showExpandedView ? 'ex': ''}`}></div>
                                        </div>
                                        {
                                            showExpandedView ?
                                            <div className="expanded-nbr-sub-itms">
                                                {
                                                    parseInt(localStorage.getItem('riskIQ')) ?
                                                    <div className={pathname.indexOf('active_dashboard') > 0?'a':''} onClick={(e) => {hrefMethod(e,'/active_dashboard')}}>{headerLabels.lane_risk_score.active_dashboard_link}</div>
                                                    : <></>
                                                }
                                                {
                                                    parseInt(localStorage.getItem('pdfParser')) || true ?
                                                    <div className={pathname.indexOf('temperature_dashboard') > 0?'a':''} onClick={(e) => {hrefMethod(e,'/temperature_dashboard')}}>{headerLabels.pdf_parser.passive_dashboard}</div>
                                                    : <></>
                                                }
                                            </div>
                                            : <></>
                                        }
                                        <ul className={`child-nodes performance ${showExpandedView ? 'ex' : ''}`} id="lane_performance_ch">
                                            {
                                                parseInt(localStorage.getItem('riskIQ')) ?
                                                <li onClick={(e) => {hrefMethod(e,'/active_dashboard')}}>
                                                    <a className={pathname.indexOf('active_dashboard') > 0?'a':''} href='/active_dashboard'>{headerLabels.lane_risk_score.active_dashboard_link}</a>
                                                </li>
                                                : <></>
                                            } 
                                            {   
                                                parseInt(localStorage.getItem('pdfParser')) || true ?
                                                <li onClick={(e) => {hrefMethod(e,'/temperature_dashboard')}}>
                                                    <a className={pathname.indexOf('temperature_dashboard') > 0?'a':''} href='/temperature_dashboard'>{headerLabels.pdf_parser.passive_dashboard}</a>
                                                </li>
                                                : <></>
                                            }
                                        </ul>
                                    </li>
                                    {/* <li className={`has-child-nodes ${pathname.indexOf('active_dashboard') > 0 || pathname.indexOf('temperature_dashboard') > 0 ? 'a' : ''} ${showExpandedView ? 'ex db': 't-tip'} hide-nav`${childsVisible.lane_performance?' disp-childs':''}} onClick={(e) => {toggleChildNav(e, 'lane_performance', {lane_performance: true, administration: false});}}>
                                        <div className="nav-arr">       
                                            <a><PerformanceIcon alt="performance_icon" className="icn" /></a>
                                            <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': 't-txt'}`}>Lane Performance </div>
                                            <div className={`dwn-arr ${showExpandedView ? 'ex': ''}`}></div>
                                        </div>
                                        {
                                            showExpandedView ?
                                            <div className="expanded-nbr-sub-itms">
                                                {
                                                    parseInt(localStorage.getItem('riskIQ')) ?
                                                    <div onClick={(e) => {hrefMethod(e,'/active_dashboard')}}>{headerLabels.lane_risk_score.active_dashboard_link}</div>
                                                    : <></>
                                                }
                                                {
                                                    parseInt(localStorage.getItem('pdfParser')) || true ?
                                                    <div onClick={(e) => {hrefMethod(e,'/temperature_dashboard')}}>{headerLabels.pdf_parser.passive_dashboard}</div>
                                                    : <></>
                                                }
                                            </div>
                                            : <></>
                                        }
                                        <ul className={`child-nodes performance ${showExpandedView ? 'ex' : ''}`}>
                                            {
                                                parseInt(localStorage.getItem('riskIQ')) ?
                                                <li onClick={(e) => {hrefMethod(e,'/active_dashboard')}}>
                                                    <a href='/active_dashboard'>{headerLabels.lane_risk_score.active_dashboard_link}</a>
                                                </li>
                                                : <></>
                                            } 
                                            {   
                                                parseInt(localStorage.getItem('pdfParser')) || true ?
                                                <li onClick={(e) => {hrefMethod(e,'/temperature_dashboard')}}>
                                                    <a href='/temperature_dashboard'>{headerLabels.pdf_parser.passive_dashboard}</a>
                                                </li>
                                                : <></>
                                            }
                                        </ul>
                                    </li> */}
                                    </>
                                    : <></>
                                }
                                {
                                    parseInt(localStorage.getItem('riskIQ')) ?
                                    <li className={`${pathname.indexOf('riskplanning') > 0 ? 'a' : ''} ${showExpandedView ? 'ex': 't-tip'}`} onClick={(e) => {hrefMethod(e,'/riskplanning')}}>
                                        <a href="/riskplanning"><PlanningIcon alt="planning_icon" className="icn" /></a>
                                        <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': 't-txt'}`}>Risk Scenario Planning</div>
                                    </li>
                                    : <></>
                                }
                                {
                                    parseInt(localStorage.getItem('pdfParser')) && false ?
                                    <li className={`${pathname.indexOf('pdfparser') > 0 ? 'a' : ''} ${showExpandedView ? 'ex': ''}`} onClick={(e) => {hrefMethod(e,'/pdfparser')}}>
                                        <a href="/pdfparser"><PdfIcon alt="pdf_icon" className="icn" /></a>
                                        <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': ''}`}>PDF Upload</div>
                                    </li>
                                    : <></>
                                }
                                {
                                    ROLES === 'super admin' || ROLES === 'group admin' || ROLES === 'company admin' ?
                                    <>
                                    <li className={`settings-brdr has-child-nodes ${pathname.indexOf('companies') > 0 || pathname.indexOf('users') > 0 || pathname.indexOf('groups') > 0 || pathname.includes('config') > 0 ? 'a' : ''} ${showExpandedView ? 'ex db': ''} show-nav${childsVisible.administration?' disp-childs':''}`} onClick={(e) => {toggleChildNav(e, 'administration', {lane_performance: false, administration: true});}}>
                                        <div className="nav-arr">
                                            <a onClick={(e) => {hrefMethod(e,ROLES === 'super admin' ? '/groups' : ROLES === 'group admin' ? '/companies' : '/users')}}><SettingsIcon alt="settings_icon" className="icn" /></a>
                                            <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': ''}`}>Administration </div>
                                            <div className={`dwn-arr ${showExpandedView ? 'ex': ''}`}></div>
                                        </div>
                                        {
                                            showExpandedView ?
                                            <div className="expanded-nbr-sub-itms" style={{whiteSpace: 'normal'}}>
                                                {
                                                    ROLES === 'super admin' || ROLES === 'group admin'?
                                                    <div className={pathname.indexOf('groups') > 0 || pathname.indexOf('companies') > 0 || pathname.indexOf('users') > 0 ? 'a':''} onClick={(e) => {hrefMethod(e,ROLES === 'super admin' ? '/groups' : '/companies')}}>{headerLabels.config.sub_menu.groups_companies}</div>
                                                    : <></>
                                                }
                                                {
                                                    ROLES === 'company admin' ?
                                                    <div className={pathname.indexOf('users') > 0 ? 'a':''} onClick={(e) => {hrefMethod(e,'/users')}}>{headerLabels.config.sub_menu.users}</div>
                                                    : <></>
                                                }
                                                {
                                                    parseInt(localStorage.getItem('pdfParser')) && false ?
                                                    <>
                                                        <div onClick={(e) => {hrefMethod(e,'/config/parserConfig')}}>{headerLabels.config.sub_menu.pdf_upload_config}</div>
                                                        <div onClick={(e) => {hrefMethod(e,'/config/productConfig')}}>{headerLabels.config.sub_menu.product_type_config}</div>
                                                    </>
                                                    : <></>
                                                }
                                            </div>
                                            : <></>
                                        }
                                        <ul className={`child-nodes settings ${ROLES === 'super admin' || ROLES === 'group admin' ? 'sa' : ''} ${showExpandedView ? 'ex' : ''}`}>
                                            {
                                                ROLES === 'super admin' || ROLES === 'group admin'?
                                                <li onClick={(e) => {hrefMethod(e,ROLES === 'super admin' ? '/groups' : '/companies')}}>
                                                    <a className={pathname.indexOf('groups') > 0 || pathname.indexOf('companies') > 0 || pathname.indexOf('users') > 0 ? 'a':''} href={ROLES === 'super admin' ? '/groups' : '/companies'}>{headerLabels.config.sub_menu.groups_companies}</a>
                                                </li>
                                                : <></>
                                            }
                                            {
                                                ROLES === 'company admin' ?
                                                <li onClick={(e) => {hrefMethod(e,'/users')}}>
                                                    <a className={pathname.indexOf('users') > 0 ? 'a':''} href='/users'>{headerLabels.config.sub_menu.users}</a>
                                                </li>
                                                : <></>
                                            }
                                            {
                                                parseInt(localStorage.getItem('pdfParser')) && false ?
                                                <>
                                                    <li onClick={(e) => {hrefMethod(e,'/config/parserConfig')}}>
                                                        <a href='/config/parserConfig'>{headerLabels.config.sub_menu.pdf_upload_config}</a>
                                                    </li>
                                                    <li onClick={(e) => {hrefMethod(e,'/config/productConfig')}}>
                                                        <a href='/config/productConfig'>{headerLabels.config.sub_menu.product_type_config}</a>
                                                    </li>
                                                </>
                                                : <></>
                                            }
                                        </ul>
                                    </li>
                                    {/* <li className={`settings-brdr has-child-nodes ${pathname.indexOf('companies') > 0 || pathname.indexOf('users') > 0 || pathname.indexOf('groups') > 0 || pathname.includes('config') > 0 ? 'a' : ''} ${showExpandedView ? 'ex db': 't-tip'} hide-nav${childsVisible.administration?' disp-childs':''}`} onClick={(e) => {toggleChildNav(e, 'administration', {lane_performance: false, administration: true});}}>
                                        <div className="nav-arr">
                                            <a><SettingsIcon alt="settings_icon" className="icn" /></a>
                                            <div className={`redesign-navlist-itm ${showExpandedView ? 'ex': 't-tip'}`}>Administration </div>
                                            <div className={`dwn-arr ${showExpandedView ? 'ex': ''}`}></div>
                                        </div>
                                        {
                                            showExpandedView ?
                                            <div className="expanded-nbr-sub-itms" style={{whiteSpace: 'normal'}}>
                                                {
                                                    ROLES === 'super admin' || ROLES === 'group admin'?
                                                    <div onClick={(e) => {hrefMethod(e,ROLES === 'super admin' ? '/groups' : '/companies')}}>{headerLabels.config.sub_menu.groups_companies}</div>
                                                    : <></>
                                                }
                                                {
                                                    ROLES === 'company admin' ?
                                                    <div onClick={(e) => {hrefMethod(e,'/users')}}>{headerLabels.config.sub_menu.users}</div>
                                                    : <></>
                                                }
                                                {
                                                    parseInt(localStorage.getItem('pdfParser')) && false ?
                                                    <>
                                                        <div onClick={(e) => {hrefMethod(e,'/config/parserConfig')}}>{headerLabels.config.sub_menu.pdf_upload_config}</div>
                                                        <div onClick={(e) => {hrefMethod(e,'/config/productConfig')}}>{headerLabels.config.sub_menu.product_type_config}</div>
                                                    </>
                                                    : <></>
                                                }
                                            </div>
                                            : <></>
                                        }
                                        <ul className={`child-nodes settings ${ROLES === 'super admin' || ROLES === 'group admin' ? 'sa' : ''} ${showExpandedView ? 'ex' : ''}`}>
                                            {
                                                ROLES === 'super admin' || ROLES === 'group admin'?
                                                <li onClick={(e) => {hrefMethod(e,ROLES === 'super admin' ? '/groups' : '/companies')}}>
                                                    <a href={ROLES === 'super admin' ? '/groups' : '/companies'}>{headerLabels.config.sub_menu.groups_companies}</a>
                                                </li>
                                                : <></>
                                            }
                                            {
                                                ROLES === 'company admin' ?
                                                <li onClick={(e) => {hrefMethod(e,'/users')}}>
                                                    <a href='/users'>{headerLabels.config.sub_menu.users}</a>
                                                </li>
                                                : <></>
                                            }
                                            {
                                                parseInt(localStorage.getItem('pdfParser')) && false ?
                                                <>
                                                    <li onClick={(e) => {hrefMethod(e,'/config/parserConfig')}}>
                                                        <a href='/config/parserConfig'>{headerLabels.config.sub_menu.pdf_upload_config}</a>
                                                    </li>
                                                    <li onClick={(e) => {hrefMethod(e,'/config/productConfig')}}>
                                                        <a href='/config/productConfig'>{headerLabels.config.sub_menu.product_type_config}</a>
                                                    </li>
                                                </>
                                                : <></>
                                            }
                                        </ul>
                                    </li> */}
                                    </>
                                    :
                                    <></>
                                }
                            </ul>
                        </div>
                    </div>
                    <div className="redesign-nav-bar-p3">
                        <img className={`carrier-lynx-logo ${showExpandedView ? 'ex' : ''}`} src={CarrierLynxLogo} alt="lynx_icon"/>
                        <div className={`redesign-nav-bar-p2 ${showExpandedView ? 'ex': ''}`} onClick={(e) => {e.stopPropagation(); setShowExpandedView(!showExpandedView); props.expandedNavBarView(!showExpandedView); }}>
                            <div className={`right-arr ${showExpandedView ? 'ex' : ''}`}></div>
                        </div>
                    </div>
                </div>
                {/* <div className="hdr" style={showExpandedView?{width: 'calc(100% - 260px)', left: '260px'}:{}}> */} 
                <div className={`redesign-nav-bar-2`}>
                    <div className="hdr-ftr-usr-name">
                        <div className="ftr-name" style={{display: 'flex', alignItems: 'center'}}>
                            <a style ={{width : props?.widthVal?? ""}} href={props.featurePath}>{props.featureName}</a>
                            {
                                props.laneName ?
                                <>
                                    <div className="right-arr ftr-path"></div>
                                    {props.routeName ? <a className="lane_name_with_route" href={props.lanePath}>{props.laneName}</a> : <span className= {props.routeName ? "" : "route-name-width"}>{props.laneName}</span>}
                                </>
                                : <></>
                            }
                            {
                                props.routeName ?
                                <>
                                    <div className="right-arr ftr-path" title={props.routeName}></div>
                                    <span>{props.routeName}</span>
                                </>
                                : <></>
                            }
                        </div>
                        <div className="usr-profile">
                            <div className="hi-usr-name">Hi, <span>{ud.firstname} {ud.lastname}</span></div>
                            <div className="redesign-user-name l-sp-07 has-child-nodes" data-testid="user-lbl">
                                <span className="usr-prof-b" onClick={toggleUserProfile}>{initials}</span>
                                {
                                showUserProfilePop ?
                                <ul className="child-nodes profile-pop">
                                    <li className="u-det">
                                        <div className="redesign-user-name"><span>{initials}</span></div>
                                        <div className="redesign-usr-info">
                                            <div className="u-p-dt">{userName}</div>
                                            <div className="u-sec-dt">{userEmail}</div>
                                        </div>
                                    </li>
                                    <li className="with-icn lnk">
                                        <a href='/profile'><UserProfileIcon alt="Manage your account" className="icn" /> My Profile</a>
                                    </li>
                                    <li className="with-icn">
                                        <ContactSupportIcon alt="Contact Support" className="icn" />
                                        <a onClick={e=>{
                                            e.stopPropagation();
                                            setShowContactSupport(true);
                                        }}>Contact Support</a>
                                    </li>
                                    <li className="with-icn lnk">
                                        <a href='/terms'><DocIcon alt="Terms Of Use" className="icn" /> Terms of Use</a>
                                    </li>
                                    <li className="with-icn lnk">
                                        <a href='/privacy'><DocIcon alt="Privacy Notice" className="icn" /> Privacy Notice</a>
                                    </li>
                                    <li className="with-icn" onClick={() => logoutWithRedirect()}>
                                        <LogoutIcon alt="Privacy Notice" className="icn" />
                                        <span>Log Out</span>
                                    </li>
                                </ul>
                                :
                                <></>
                            }
                            </div>                                
                        </div>
                    </div>
                </div> 
            </div>
            { 
                showExpandedView ?
                <div 
                    className={`redesign-overlay ex`} 
                    onClick={(e) => {
                        e.stopPropagation();
                        setChildsVisible({lane_performance: false, administration: false});
                        setShowExpandedView(!showExpandedView); 
                        props.expandedNavBarView(!showExpandedView); }}
                    ></div>
                : <></>
            }
            {
                showContactSupport ?
                <div className="cntxt-overlay">
                    <div className='cwl-dt-popup' style={{height: 'auto'}}>
                        <div  className='df' style={{alignItems: 'center'}}>
                            <div className="cwl-dt-hdr">Need Assistance?</div>
                            <div
                                className="cwl-close-icn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setShowContactSupport(false);
                                }}
                            >
                                <a className="material-icons close-icn">close</a>
                            </div>
                        </div>
                        <div style={{height:'calc(100%-60px)'}}>
                        <PerfectScrollbar>
                        <div className='u-det-in'>
                            <div className="c-s-sp">If you encounter issues managing your team, account information, or if you notice something out of the ordinary, please reach out to report a problem.</div>
                            <div className="c-s-sp">
                            <div><b>Support Number (24/7 availability):</b></div>
                            <div>1-800-843-8367 (Toll Free)</div>
                            <div>1-978-927-7033 (International)</div>
                            </div>
                            <div className="c-s-sp">
                            <div><b>Support Email: </b></div>
                            <div><a href="mailto:sensitech.support@carrier.com">sensitech.support@carrier.com</a></div>
                            </div>
                            <div className="c-s-sp">Your assistance in improving the LYNX experience is greatly appreciated, and we'll be happy to provide direct support. Thank you!</div>
                        </div>                            
                        </PerfectScrollbar>
                        </div>
                    </div>
                </div>
                : <></>
            }
            {/* {checkLogout ? (
                <>
                <div className="sh-overlay"></div>
                <div className="uh-overlay"></div>
                <div className="cntxt-overlay s">
                    <div className="cntxt-err-pop">
                        <SessionExpired 
                            onChange={() => logoutWithRedirect()}
                        />
                    </div>
                </div>
                </>
            ) : <></>
            } */}
            {
                serverError ? 
                    <>
                        <div className={`cntxt-overlay s pos`}>
                            <div className="cntxt-err-pop pos">
                                <ServerError />
                            </div>
                        </div>
                    </>
                : 
                maintenanceMode ? 
                    <>
                        <div className={`cntxt-overlay s`}>
                            <div className="cntxt-err-pop">
                                <MaintenanceMode />
                            </div>
                        </div>
                    </>
                : networkError ?
                    <>
                        <div className={`cntxt-overlay s`}>
                            <div className="cntxt-err-pop">
                                <div className="h1">No Connectivity!</div>
                                <div className="b">Please check your internet connection.</div>
                                <div className="f"><a href={window.location.href} className="c-btn">Reload</a></div>
                            </div>
                        </div>
                    </>
                : ''
            }
        </>
    );

}
export default Header;