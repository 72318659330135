import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utility/utility';
//import { viewGroup } from 'redux-store/actions/company';
const intialState = {
    companies: [],
    paginationCompanies: [],
    companiesLoading: false,
    companiesMeta: {},
    companyDetails: {},
    loading: true,
    userLoading: false,
    companyUserList: [],
    errors: {},
    companyFlag: false,
    error_message: '',
    deviceLoading: false,
    companyDeviceList: [],
    shipmentCountMessage: '',
    deleteMessage: '',
    logout: false,
    company_details: {},
    detailsMessage: '',
    detailsLoading: false,
    groups:[],
    groupUserList:[],
    groupDeviceList:[],
    groupCompanyList:[],
    groupFlag:false,
    groupShipmentCountMessage:'',
    groupDeleteMessage:'',
    groupCompanyLoading:false,
    groupUserLoading:false,
    groupLoading: true,
    groupDeviceLoading:false,
    viewGroup:[],
    viewGroupLoading:false,
    assignMessage:'',
    makeGroupAdminMessage:'',
    featuresLoading: false,
    features: [],
    companiesMeta: {},
    companyUsersListMeta: {},
    groupData: {success: false, data: [], message: ''},
    oldCompany: '',
    companyStatusObj: {},
};
const companyListStart = (state, action) => {
    return updateObject(state, { 
        companies: [],
        companiesLoading: true 
    });
};
const sendCompanyList = (state, action) => {
    let LIST = [...action.data.data];
    if(process.env.REACT_APP_DISABLE_SA_DETAILS === '1' && localStorage.getItem('company').toLowerCase() !== process.env.REACT_APP_SA_COMPANY) {
        LIST = LIST.filter(c=>c.name.toLowerCase()!==process.env.REACT_APP_SA_COMPANY);
    }    
        
    state.companiesList = [...LIST];
    
    return updateObject(state, { companies: LIST, paginationCompanies: LIST, companiesLoading: false,  companiesMeta: action.data.meta});
};

const companyUserListStart = (state, action) => {
    return updateObject(state, { userLoading: true , companyUserList: [] });
};
const sendCompanyUserList = (state, action) => {
    let LIST = [...action.data];
    if (action.companyUsersListMeta) {
        if(action.companyUsersListMeta.current_page === 1) {
            state.companiesUserList = [...LIST];
        } else {
            LIST = [...state.companiesUserList, ...action.data];
            state.companiesUserList = [...LIST];
        }
    }
    return updateObject(state, { companyUserList: LIST, userLoading: false, companyUsersListMeta: action.companyUsersListMeta});
};

const sendNewCompanyStart = (state, action) => {
    return updateObject(state, { companySaveLoading: true });
};

const sendNewCompanySuccess = (state, action) => {    
    return updateObject(state, { createCompanyResponse: action.data, companySaveLoading: false });
};
const sendNewCompanyFailed = (state, action) => {    
    return updateObject(state, {companyFormErrors: action.data, companySaveLoading: false});
};

const clearCompanyFormErrors = (state, action) => {    
    return updateObject(state, {companyFormErrors: {}, companySaveLoading: false});
};

const sendUpdateCompanyStart = (state, action) => {
    return updateObject(state, { companySaveLoading: true });
};

const sendUpdateCompanySuccess = (state, action) => {    
    return updateObject(state, { createCompanyResponse: action.data, companySaveLoading: false });
};
const sendUpdateCompanyFailed = (state, action) => {    
    return updateObject(state, {companyFormErrors: action.data, companySaveLoading: false});
};

const companyDeviceListStart = (state, action) => {
    return updateObject(state, { companyDeviceList: [], deviceLoading: true });
};

const sendCompanyDevicesList = (state, action) => {
    return updateObject(state, { companyDeviceList: action.list, deviceLoading: action.flag });
};

const sendCompanyShipmentCountSuccess = (state, action) => {
    return updateObject(state, { shipmentCountMessage: action.message });
};
const sendDeleteCompanySuccess = (state, action) => {
    return updateObject(state, { deleteMessage: action.message });
};
const confirmLogout = (state, action) => {
    return updateObject(state, { logout: action.flag });
};
const setNetworkConnectionError = (state, action) => {
    return updateObject(state, { networkError: action.flag });
};
const setInternalServerError = (state, action) => {
    return updateObject(state, { serverError: action.flag });
};
const sendCompanyDetails = (state, action) => {
    return updateObject(state, {
        detailsLoading: false,
        companyDetails: action.data,
        detailsMessage: action.message,
    });
};
const companyDetailsStart = (state, action) => {
    return updateObject(state, { detailsLoading: true });
};
const sendGroupList = (state, action) => {
    let groups = [...action.data];
    if(process.env.REACT_APP_DISABLE_SA_DETAILS === '1' && localStorage.getItem('company').toLowerCase() !== process.env.REACT_APP_SA_COMPANY) {
        groups = groups.map(g=>{ return {...g, company_group: g?.company_group?.filter(cg=>cg.company?.name?.toLowerCase()!==process.env.REACT_APP_SA_COMPANY)}});
        groups.map(g => {
            g.companiesCount = g.company_group?.length||0;
        });
    }
    // groups.sort((a,b) => {
    //     if (a.name.toUpperCase() < b.name.toUpperCase()) {
    //         return -1;
    //     }
    //     if (b.name.toUpperCase() < a.name.toUpperCase()) {
    //         return 1;
    //     }
    //     return 0;
    // });
    return updateObject(state, { groups, groupLoading: false });
};
const groupListStart = (state, action) => {
    return updateObject(state, { groupLoading: true, groups: [] });
};
const groupUserListStart = (state, action) => {
    return updateObject(state, { groupUserLoading: true , groupUserList: []});
};
const sendGroupUserList = (state, action) => {
    let LIST = [...action.data];
    if(process.env.REACT_APP_DISABLE_SA_DETAILS === '1' && localStorage.getItem('company').toLowerCase() !== process.env.REACT_APP_SA_COMPANY) {
        LIST = LIST.filter(user=>user.company?.toLowerCase()!==process.env.REACT_APP_SA_COMPANY)
    }
    if (action.groupUsersListMeta) {
        if(action.groupUsersListMeta.current_page === 1) {
            state.groupUserList = [...LIST];
        } else {
            LIST = [...state.groupUserList, ...action.data];
            state.groupUserList = [...LIST];
        }
    }
    return updateObject(state, { groupUserList: LIST, groupUserLoading: false, groupUsersListMeta: action.groupUsersListMeta});
};

const sendNewGroupStart = (state, action) => {
    return updateObject(state, { errors: {}, groupFlag: false, error_message: '' });
};

const sendNewGroupSuccess = (state, action) => {
    return updateObject(state, { groupData: action.data });
};

const groupDeviceListStart = (state, action) => {
    return updateObject(state, { groupDeviceList: [], groupDeviceLoading: true });
};

const sendGroupDevicesList = (state, action) => {
    return updateObject(state, { groupDeviceList: action.list, groupDeviceLoading: action.flag });
};
const groupCompanyListStart = (state, action) => {
    return updateObject(state, { groupCompanyList: [], groupCompanyLoading: true });
};

const sendGroupCompanyList = (state, action) => {
    return updateObject(state, { groupCompanyList: action.list, groupCompanyLoading: action.flag });
};

const sendGroupShipmentCountSuccess = (state, action) => {
    return updateObject(state, { groupShipmentCountMessage: action.message });
};
const sendDeleteGroupSuccess = (state, action) => {
    return updateObject(state, { groupDeleteMessage: action.message });
};
const viewGroupStart = (state,action)=>{
    return updateObject(state,{viewGroup:[],viewGroupLoading:true});
}
const viewGroupSuccess = (state,action)=>{
    return updateObject(state,{viewGroup:action.data,viewGroupLoading:false})
}
const assignCompaniesToGroupSuccess = (state,action)=>{
    return updateObject(state,{assignMessage:action.message})
}
const removeAssignedCompaniesSuccess = (state,action)=>{
    return updateObject(state,{assignMessage:action.message})
}
const makeGroupAdminSucess = (state,action) =>{
    return updateObject(state,{makeGroupAdminMessage:action.message});
}
const getCompanyFeaturesStart = (state,action) =>{
    return updateObject(state,{featuresLoading: true});
}
const getCompanyFeaturesSuccess = (state,action) =>{
    return updateObject(state,{features: action.data, featuresLoading: false});
}
const updateCompanyFeaturesStart = (state,action) =>{
    return updateObject(state,{updateFeaturesLoading: true});
}
const updateCompanyFeaturesSuccess = (state,action) =>{
    return updateObject(state,{features: action.data, updateFeaturesLoading: false});
}
const sendEnableDisableCompanySuccess = (state, action) => {
    return updateObject(state, { companyStatusObj: action.data });
};

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANIES_SUCCESS:
            return sendCompanyList(state, action);
        case actionTypes.GET_COMPANIES_START:
            return companyListStart(state, action);
        case actionTypes.GET_COMPANY_USER_LIST_START:
            return companyUserListStart(state, action);
        case actionTypes.GET_COMPANY_USERS_SUCCESS:
            return sendCompanyUserList(state, action);
        case actionTypes.ADD_NEW_COMPANY_START:
            return sendNewCompanyStart(state, action);
        case actionTypes.ADD_NEW_COMPANY_SUCCESS:
            return sendNewCompanySuccess(state, action);
        case actionTypes.ADD_NEW_COMPANY_FAILED:
            return sendNewCompanyFailed(state, action);
        case actionTypes.CLEAR_COMPANY_FORM_ERRORS:
            return clearCompanyFormErrors(state, action);
        case actionTypes.UPDATE_COMPANY_START:
            return sendUpdateCompanyStart(state, action);
        case actionTypes.UPDATE_COMPANY_SUCCESS:
            return sendUpdateCompanySuccess(state, action);
        case actionTypes.UPDATE_COMPANY_FAILED:
            return sendUpdateCompanyFailed(state, action);
        case actionTypes.GET_COMPANY_DEVICE_LIST_START:
            return companyDeviceListStart(state, action);
        case actionTypes.GET_COMPANY_DEVICES_SUCCESS:
            return sendCompanyDevicesList(state, action);
        case actionTypes.GET_COMPANY_SHIPMENT_COUNT_SUCCESS:
            return sendCompanyShipmentCountSuccess(state, action);
        case actionTypes.DELETE_COMPANY_SUCCESS:
            return sendDeleteCompanySuccess(state, action);
        case actionTypes.USER_LOGOUT:
            return confirmLogout(state, action);
        case actionTypes.NO_NETWORK_CONNECTION:
            return setNetworkConnectionError(state, action);
        case actionTypes.INTERNAL_SERVER_ERROR:
            return setInternalServerError(state, action);
        case actionTypes.GET_COMPANY_DETAILS_SUCCESS:
            return sendCompanyDetails(state, action);
        case actionTypes.GET_COMPANY_DETAILS_START:
            return companyDetailsStart(state, action);
        case actionTypes.GET_GROUPS_LIST_SUCCESS:
            return sendGroupList(state, action);
        case actionTypes.GET_GROUPS_LIST_START:
            return groupListStart(state, action);
        case actionTypes.GET_GROUPS_USERS_LIST_START:
            return groupUserListStart(state, action);
        case actionTypes.GET_GROUPS_USERS_SUCCESS:
            return sendGroupUserList(state, action);
        case actionTypes.ADD_NEW_GROUP_START:
            return sendNewGroupStart(state, action);
        case actionTypes.ADD_NEW_GROUP_SUCCESS:
            return sendNewGroupSuccess(state, action);
        case actionTypes.GET_GROUPS_DEVICES_LIST_START:
            return groupDeviceListStart(state, action);
        case actionTypes.GET_GROUPS_DEVICES_LIST_SUCCESS:
            return sendGroupDevicesList(state, action);
        case actionTypes.GET_GROUPS_COMPANIES_LIST_START:
            return groupCompanyListStart(state, action);
        case actionTypes.GET_GROUPS_COMPANIES_LIST_SUCCESS:
            return sendGroupCompanyList(state, action);
        case actionTypes.GET_GROUP_SHIPMENT_COUNT_SUCCESS:
            return sendGroupShipmentCountSuccess(state, action);
        case actionTypes.DELETE_GROUP_SUCCESS:
            return sendDeleteGroupSuccess(state, action);
        case actionTypes.VIEW_GROUP_START:
            return viewGroupStart(state,action);
        case actionTypes.VIEW_GROUP_SUCCESS:
            return viewGroupSuccess(state,action);
        case actionTypes.ASSIGN_COMPANIES_TO_GROUP_SUCCESS:
            return assignCompaniesToGroupSuccess(state,action);
        case actionTypes.REMOVE_ASSIGNED_COMPANIES_SUCCESS:
            return removeAssignedCompaniesSuccess(state,action);
        case actionTypes.MAKE_GROUP_ADMIN_SUCCESS:
            return makeGroupAdminSucess(state,action);
        case actionTypes.GET_COMPANY_FEATURES_START:
            return getCompanyFeaturesStart(state,action);
        case actionTypes.GET_COMPANY_FEATURES_SUCCESS:
            return getCompanyFeaturesSuccess(state,action);
        case actionTypes.UPDATE_COMPANY_FEATURES_START:
            return updateCompanyFeaturesStart(state,action);
        case actionTypes.UPDATE_COMPANY_FEATURES_SUCCESS:
            return updateCompanyFeaturesSuccess(state,action);
        case actionTypes.ENABLE_DISABLE_COMPANY:
            return sendEnableDisableCompanySuccess(state, action);
        default:
            return state;
    }
};

export default reducer;
