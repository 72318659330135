import React from 'react';
const PerformanceIcon = (props) => {
    return (<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_5900_3036)">
            <path className='nav-icon' d="M15 18H0V4C0 3.20435 0.31607 2.44129 0.87868 1.87868C1.44129 1.31607 2.20435 1 3 1H12C12.7956 1 13.5587 1.31607 14.1213 1.87868C14.6839 2.44129 15 3.20435 15 4V18ZM17 18H24V13H17V18ZM19 5H17V11H24V10C23.9984 8.67441 23.4711 7.40356 22.5338 6.46622C21.5964 5.52888 20.3256 5.00159 19 5ZM3.058 20C3.02137 20.1642 3.00193 20.3318 3 20.5C3 21.163 3.26339 21.7989 3.73223 22.2678C4.20107 22.7366 4.83696 23 5.5 23C6.16304 23 6.79893 22.7366 7.26777 22.2678C7.73661 21.7989 8 21.163 8 20.5C7.99807 20.3318 7.97863 20.1642 7.942 20H3.058ZM17.058 20C17.0214 20.1642 17.0019 20.3318 17 20.5C17 21.163 17.2634 21.7989 17.7322 22.2678C18.2011 22.7366 18.837 23 19.5 23C20.163 23 20.7989 22.7366 21.2678 22.2678C21.7366 21.7989 22 21.163 22 20.5C21.9981 20.3318 21.9786 20.1642 21.942 20H17.058Z" fill="#999999" />
        </g>
        <defs>
            <clipPath id="clip0_5900_3036">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>)
}
export default PerformanceIcon;
