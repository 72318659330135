import React from 'react';
// import PerfectScrollBar from 'perfect-scrollbar';
// import 'react-perfect-scrollbar/dist/css/styles.css';
import SimpleBarReact from "simplebar-react";

import 'simplebar-react/dist/simplebar.min.css';
// import "simplebar/src/simplebar.css";
// import 'simplebar-react/dist/simplebar.min.css';

function ScrollBar(props){
    if(window.screen.width<1024) {
        return(
            <>{props.children}</>
        );
    }
    else{
        return(
            <SimpleBarReact 
                id={props.id||''} 
                style={{ height: "100%", outline: 0 }}
            >
            {props.children}
            </SimpleBarReact>
        );
    
    }
}

export default ScrollBar;