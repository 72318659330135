import React from 'react';
const ServerError = () => {
    return (
        <>
        <div className="n-w-err-icn" unit-test-id='n-w-err-icn'></div>
        <div className="e-s">
            <div className="lgn-f-hdr" unit-test-id='lgn-f-hdr'>Oops! Something went wrong</div>
        </div>

        <div className="r-b-small">Lynx Logix has encountered an unexpected condition that has prevented your request from being properly fulfilled. This exception has been logged for further investigation by our support team.</div>
        <div className="r-b-small rbs-mt" unit-test-id='rbs-mt'>If you feel you've reached this screen by mistake, please try the following:</div>

        <ul className="e-ul r-b-small">
            <li><b>Refresh the page:</b> In many cases, a simple refresh can resolve the exception.</li>
            <li><b>Check your internet connection:</b> Ensure you currently have a stable connection; intermittent connectivity may cause exceptions.</li>
            <li><b>Clear your browser cache:</b> This may help resolve any exceptions caused by out of date or corrupted cache files.</li>
        </ul>

        <div className="r-b-small">If the situation persists, don't hesitate to reach out to our dedicated support team for further assistance:</div>
        
            
        <a 
            href="mailto:sensitech.support@carrier.com"
            className={`p-w-btn pwb-margin`}
        >Contact Support</a>
        </>
    )
};
export default ServerError;